var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    [
      _c("h2", [_vm._v("Observable")]),
      _c("h4", [_vm._v("Example")]),
      _c(
        "Controls",
        { attrs: { inline: true, route: "/observable/fullscreen" } },
        [
          _c("Example"),
          _c("h5", { attrs: { slot: "filename" }, slot: "filename" }, [
            _vm._v("observable.vue")
          ]),
          _c("pre", { attrs: { slot: "pug" }, slot: "pug" }, [
            _c("code", { attrs: { lang: "pug" } }, [
              _c("span", { staticClass: "token tag" }, [_vm._v("div")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("// events take either a method name or logic content")
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v(
                  "// the complete event happens after all children have been initialized and bound"
                )
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Scene"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _vm._v("@"),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("complete")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"complete"')
                    ]),
                    _vm._v(" @before"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("-")
                    ]),
                    _vm._v("render$"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"beforeRender"')
                    ]),
                    _vm._v(" @after"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("-")
                    ]),
                    _vm._v("render$"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"++frames"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v(
                  "// you can use v-model bindings instead of event entity reference"
                )
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Box"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _vm._v(":"),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("position")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("-")
                    ]),
                    _c("span", { staticClass: "token number" }, [_vm._v("2")])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "token attr-name" }, [_vm._v("0")]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(' 5]" :'),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("scaling")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"scale.box"')
                    ]),
                    _vm._v(" v"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("-")
                    ]),
                    _vm._v("model"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"box"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Sphere"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _vm._v(":"),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("position")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token number" }, [_vm._v("2")])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "token attr-name" }, [_vm._v("0")]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(' 5]" :'),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("scaling")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"scale.sphere"')
                    ]),
                    _vm._v(" @entity"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"onSphere"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("div"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("v-text")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"`Frames: ${frames}`"')
                    ]),
                    _vm._v(" style"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v(
                        '"position: absolute; color: white; bottom: 0; padding: 15px"'
                      )
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n")
            ])
          ]),
          _c("pre", { attrs: { slot: "html" }, slot: "html" }, [
            _c("code", { attrs: { lang: "markup" } }, [
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("div")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v(
                  "<!-- events take either a method name or logic content-->"
                )
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v(
                  "<!-- the complete event happens after all children have been initialized and bound-->"
                )
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Scene")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("@complete")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("complete"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("@before-render$")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("beforeRender"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("@after-render$")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("++frames"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v(
                  "<!-- you can use v-model bindings instead of event entity reference-->"
                )
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Box")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":position")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("[-2, 0, 5]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":scaling")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("scale.box"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("v-model")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("box"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Box")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Sphere")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":position")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("[2, 0, 5]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":scaling")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("scale.sphere"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("@entity")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("onSphere"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Sphere")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Scene")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("div")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("v-text")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("`Frames: ${frames}`"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token style-attr language-css" }, [
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v(" "),
                    _c("span", { staticClass: "token attr-name" }, [
                      _vm._v("style")
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('="')
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token property" }, [
                      _vm._v("position")
                    ]),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v(" absolute"),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(";")
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token property" }, [
                      _vm._v("color")
                    ]),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v(" white"),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(";")
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token property" }, [
                      _vm._v("bottom")
                    ]),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v(" 0"),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(";")
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token property" }, [
                      _vm._v("padding")
                    ]),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v(" 15px")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("div")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("div")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ])
            ])
          ]),
          _c("pre", { attrs: { slot: "script" }, slot: "script" }, [
            _c("code", { attrs: { lang: "javascript" } }, [
              _c("span", { staticClass: "token keyword" }, [_vm._v("export")]),
              _vm._v(" "),
              _c("span", { staticClass: "token keyword" }, [_vm._v("default")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token function" }, [_vm._v("data")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token keyword" }, [_vm._v("return")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n      box"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token keyword" }, [_vm._v("null")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n      sphere"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token keyword" }, [_vm._v("null")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n      time"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(":")]),
              _vm._v(" performance"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _c("span", { staticClass: "token function" }, [_vm._v("now")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n      frames"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("0")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n\n  computed"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token function" }, [_vm._v("scale")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token keyword" }, [_vm._v("let")]),
              _vm._v(" a "),
              _c("span", { staticClass: "token operator" }, [_vm._v("=")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("2")]),
              _vm._v(" "),
              _c("span", { staticClass: "token operator" }, [_vm._v("+")]),
              _vm._v(" Math"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _c("span", { staticClass: "token function" }, [_vm._v("cos")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
              _c("span", { staticClass: "token keyword" }, [_vm._v("this")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _vm._v("time "),
              _c("span", { staticClass: "token operator" }, [_vm._v("*")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("0.001")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token keyword" }, [_vm._v("let")]),
              _vm._v(" b "),
              _c("span", { staticClass: "token operator" }, [_vm._v("=")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("2")]),
              _vm._v(" "),
              _c("span", { staticClass: "token operator" }, [_vm._v("+")]),
              _vm._v(" Math"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _c("span", { staticClass: "token function" }, [_vm._v("sin")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
              _c("span", { staticClass: "token keyword" }, [_vm._v("this")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _vm._v("time "),
              _c("span", { staticClass: "token operator" }, [_vm._v("*")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("0.001")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token keyword" }, [_vm._v("return")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n        box"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("[")]),
              _vm._v("a"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v(" b"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("1")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("]")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n        sphere"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("[")]),
              _vm._v("b"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v(" a"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("1")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("]")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n\n  methods"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token function" }, [
                _vm._v("beforeRender")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token keyword" }, [_vm._v("this")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _vm._v("time "),
              _c("span", { staticClass: "token operator" }, [_vm._v("=")]),
              _vm._v(" performance"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _c("span", { staticClass: "token function" }, [_vm._v("now")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n\n    "),
              _c("span", { staticClass: "token function" }, [
                _vm._v("onSphere")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
              _c("span", { staticClass: "token parameter" }, [_vm._v("event")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n      console"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _c("span", { staticClass: "token function" }, [_vm._v("log")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
              _c("span", { staticClass: "token string" }, [
                _vm._v("'onSphere'")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v(" event"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("// the entity event includes entity reference")
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token keyword" }, [_vm._v("this")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _vm._v("sphere "),
              _c("span", { staticClass: "token operator" }, [_vm._v("=")]),
              _vm._v(" event"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _vm._v("entity"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n\n    "),
              _c("span", { staticClass: "token function" }, [
                _vm._v("complete")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
              _c("span", { staticClass: "token parameter" }, [_vm._v("event")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n      console"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _c("span", { staticClass: "token function" }, [_vm._v("log")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
              _c("span", { staticClass: "token string" }, [
                _vm._v("'complete'")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v(" event"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
              _vm._v("\n      console"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _c("span", { staticClass: "token function" }, [_vm._v("log")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
              _c("span", { staticClass: "token string" }, [_vm._v("'box'")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v(" "),
              _c("span", { staticClass: "token keyword" }, [_vm._v("this")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _vm._v("box"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
              _vm._v("\n      console"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _c("span", { staticClass: "token function" }, [_vm._v("log")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
              _c("span", { staticClass: "token string" }, [_vm._v("'sphere'")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v(" "),
              _c("span", { staticClass: "token keyword" }, [_vm._v("this")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _vm._v("sphere"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
              _vm._v("\n")
            ])
          ])
        ],
        1
      ),
      _c("h4", [_vm._v("Description")]),
      _vm._v(" "),
      _c("p", [
        _vm._v("Interact with observables exposed by Babylonjs objects")
      ]),
      _vm._v(" "),
      _c("h4", [_vm._v("Details")]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("h4", [_vm._v("Usage")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "For your convenience here is a mapping of listner attribute to BabylonJS Observable on all of the current Scene class observables."
        )
      ]),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _vm._m(4),
      _vm._v(" "),
      _vm._m(5)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("The "),
      _c(
        "a",
        { attrs: { href: "https://doc.babylonjs.com/how_to/observables" } },
        [_vm._v("BabylonJS guide to observables")]
      ),
      _vm._v(" and Vue's "),
      _c(
        "a",
        {
          attrs: {
            href: "https://vuejs.org/v2/guide/components-custom-events.html"
          }
        },
        [_vm._v("custom events documentation")]
      ),
      _vm._v(" can be helpful here.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v(
          "Components in this libraray expose observable subscribers as integrated with the Vue event system"
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v("Attributes in the form "),
        _c("code", [_vm._v("@some-name$")]),
        _vm._v(" will become an observer using the "),
        _c("code", [_vm._v("onSomeNameObservable")]),
        _vm._v(" property on the underlying Babylonjs object.")
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "The listener will attempt to find a matching observable on the underlying object and in the event that one does not exist will attempt to match one on the parent scene."
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v("Use the "),
        _c("code", [_vm._v(".once")]),
        _vm._v(
          " modifier on the listner attribute to only run the listner function once."
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "The implementation is based on custom events within Vue and uses Vue's "
        ),
        _c("code", [_vm._v("$listeners")]),
        _vm._v(
          " property to efficiently register subscribers with their associated Observables."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [
        _vm._v(
          "Scene observables checked during each renderLoop (in the order they are checked)"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", [
      _c("thead", [
        _c("tr", [
          _c("th", [_vm._v("Event Prop")]),
          _vm._v(" "),
          _c("th", [_vm._v("Scene Object Observable Name")])
        ])
      ]),
      _vm._v(" "),
      _c("tbody", [
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@before-animations$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onBeforeAnimationsObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@after-animations$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onAfterAnimationsObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@before-physics$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onBeforePhysicsObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@after-physics$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onAfterPhysicsObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@before-render$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onBeforeRenderObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@before-render-targets-render$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onBeforeRenderTargetsRenderObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@after-render-targets-render$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onAfterRenderTargetsRenderObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@before-camera-render$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onBeforeCameraRenderObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@before-active-meshes-evaluation$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onBeforeActiveMeshesEvaluationObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@after-active-meshes-evaluation$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onAfterActiveMeshesEvaluationObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@before-particles-rendering$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onBeforeParticlesRenderingObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@after-particles-rendering$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onAfterParticlesRenderingObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@before-draw-phase$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onBeforeDrawPhaseObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@after-draw-phase$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onAfterDrawPhaseObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@after-camera-render$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onAfterCameraRenderObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@after-render$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onAfterRenderObservable")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("Other Scene observables")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", [
      _c("thead", [
        _c("tr", [
          _c("th", [_vm._v("Event Prop")]),
          _vm._v(" "),
          _c("th", [_vm._v("Scene Object Observable Name")])
        ])
      ]),
      _vm._v(" "),
      _c("tbody", [
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@before-rendering-group$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onBeforeRenderingGroupObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@after-rendering-group$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onAfterRenderingGroupObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@before-sprites-rendering$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onBeforeSpritesRenderingObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@after-sprites-rendering$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onAfterSpritesRenderingObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@before-step$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onBeforeStepObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@after-step$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onAfterStepObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@new-camera-added$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onNewCameraAddedObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@camera-removed$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onCameraRemovedObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@data-loaded$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onDataLoadedObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@dispose$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onDisposeObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@new-geometry-added$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onNewGeometryAddedObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@geometry-removed$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onGeometryRemovedObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@pre-keyboard$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onPreKeyboardObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@keyboard$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onKeyboardObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@new-light-added$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onNewLightAddedObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@light-removed$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onLightRemovedObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@new-mesh-added$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onNewMeshAddedObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@mesh-removed$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onMeshRemovedObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@new-transform-node-added$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onNewTransformNodeAddedObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@transform-node-removed$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onTransformNodeRemovedObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@pre-pointer$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onPrePointerObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@pointer$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onPointerObservable")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_c("code", [_vm._v("@ready$")])]),
          _vm._v(" "),
          _c("td", [_vm._v("onReadyObservable")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }