var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Scene",
    [
      _c("Camera", { attrs: { type: "arcRotate", radius: 3 } }),
      _c(
        "HemisphericLight",
        { attrs: { diffuse: "#F00", specular: "#0F0", direction: [-1, 1, 0] } },
        [_c("Property", { attrs: { name: "groundColor", color: "#0F0" } })],
        1
      ),
      _c(
        "Sphere",
        { attrs: { position: [-1.5, 0, 0] } },
        [
          _c(
            "Material",
            [
              _c("Texture", {
                attrs: {
                  src: "https://www.babylonjs-playground.com/textures/grass.png"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Sphere",
        [
          _c(
            "Material",
            [
              _c("Texture", {
                attrs: {
                  type: "emissive",
                  src: "https://www.babylonjs-playground.com/textures/grass.png"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Sphere",
        { attrs: { position: [1.5, 0, 0] } },
        [
          _c(
            "Material",
            { attrs: { diffuse: "#F00" } },
            [
              _c("Texture", {
                attrs: {
                  type: "ambient",
                  src: "https://www.babylonjs-playground.com/textures/grass.png"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }