import { render, staticRenderFns } from "./scene.vue?vue&type=template&id=bb72fc5c&lang=pug&"
import script from "./scene.vue?vue&type=script&lang=js&"
export * from "./scene.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/brainbacon/Projects/vue-babylonjs/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('bb72fc5c', component.options)
    } else {
      api.reload('bb72fc5c', component.options)
    }
    module.hot.accept("./scene.vue?vue&type=template&id=bb72fc5c&lang=pug&", function () {
      api.rerender('bb72fc5c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "site/scene.vue"
export default component.exports