var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Entity",
    { attrs: { rotation: [-Math.PI / 2, 0, 0], position: [0, 0, -0.15] } },
    [
      _c(
        "ExtrudePolygon",
        {
          attrs: {
            options: {
              shape: [
                _vm.$vector([0.133, 0, 0.5]),
                _vm.$vector([0.346, 0, 0.5]),
                _vm.$vector([0, 0, -0.1]),
                _vm.$vector([0, 0, 0.269])
              ],
              depth: 0.3,
              sideOrientation: _vm.BABYLON.Mesh.DOUBLESIDE
            }
          }
        },
        [
          _c("Material", { attrs: { diffuse: "#34495E", specular: "#34495E" } })
        ],
        1
      ),
      _c(
        "ExtrudePolygon",
        {
          attrs: {
            options: {
              shape: [
                _vm.$vector([0.346, 0, 0.5]),
                _vm.$vector([0.577, 0, 0.5]),
                _vm.$vector([0, 0, -0.5]),
                _vm.$vector([0, 0, -0.1])
              ],
              depth: 0.3,
              sideOrientation: _vm.BABYLON.Mesh.DOUBLESIDE
            }
          }
        },
        [
          _c("Material", { attrs: { diffuse: "#41B883", specular: "#41B883" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }