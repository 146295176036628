var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Scene",
    [
      _c("Property", { attrs: { name: "clearColor", color: "#FFF" } }),
      _c("Camera", { attrs: { type: "arcRotate" } }),
      _c(
        "HemisphericLight",
        [_c("property", { attrs: { name: "intensity", float: 2 } })],
        1
      ),
      _c(
        "Entity",
        { attrs: { scaling: [5, 5, 5] } },
        [
          _c("Animation", {
            attrs: { property: "rotation.y", duration: 5, end: -Math.PI * 2 }
          }),
          _c("Side"),
          _c("Entity", { attrs: { scaling: [-1, 1, 1] } }, [_c("Side")], 1)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }