var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Scene",
    [
      _c("Camera", {
        attrs: { type: "arcRotate", radius: 15, beta: Math.PI / 4 }
      }),
      _c("HemisphericLight"),
      _c("Sphere", { attrs: { position: [6, 0, 0], scaling: [1, 1, 1] } }),
      _c("Torus", { attrs: { position: [4, 0, 0] } }),
      _c(
        "Box",
        { attrs: { "v-model": _vm.myBox, position: [2.5, 0, 0] } },
        [
          _c("Material", {
            attrs: { diffuse: "#F00", metallic: 0, roughness: 1 }
          })
        ],
        1
      ),
      _c(
        "Cylinder",
        { attrs: { position: [1, 0, 0] } },
        [
          _c("Material", {
            attrs: { diffuse: "#0F0", metallic: 0, roughness: 1 }
          })
        ],
        1
      ),
      _c("IcoSphere", { attrs: { position: [-1, 0, 0] } }),
      _c("TorusKnot", { attrs: { position: [-6, 0, 0] } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }