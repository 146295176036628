var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "controls shadow-dark" }, [
    _c("div", { staticClass: "frow" }, [
      _c("div", { staticClass: "col-xs-1-1" }, [
        _c(
          "div",
          { staticClass: "controls-container" },
          [
            _c(
              "button",
              {
                staticClass: "toggle-code",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    _vm.isCodeVisible = !_vm.isCodeVisible
                  }
                }
              },
              [_vm._v(_vm._s((_vm.isCodeVisible ? "Hide" : "Show") + " Code"))]
            ),
            _c("router-link", { attrs: { to: _vm.route } }, [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.inline,
                      expression: "inline"
                    }
                  ],
                  staticClass: "full-screen",
                  attrs: { type: "button" }
                },
                [_vm._v("Full Screen")]
              )
            ]),
            _vm._t("default")
          ],
          2
        )
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isCodeVisible,
              expression: "isCodeVisible"
            }
          ],
          staticClass: "col-xs-1-1"
        },
        [
          _c("div", { staticClass: "code-controls" }, [
            _c("div", { staticClass: "frow justify-start" }, [
              _c(
                "button",
                {
                  staticClass: "code-html-button",
                  attrs: { type: "button", disabled: !_vm.isPug },
                  on: {
                    click: function($event) {
                      _vm.isPug = false
                    }
                  }
                },
                [_vm._v("HTML")]
              ),
              _c(
                "button",
                {
                  staticClass: "code-pug-button",
                  attrs: { type: "button", disabled: _vm.isPug },
                  on: {
                    click: function($event) {
                      _vm.isPug = true
                    }
                  }
                },
                [_vm._v("Pug")]
              )
            ])
          ]),
          _c("div", { staticClass: "code-container" }, [
            _c(
              "div",
              { staticClass: "name-container" },
              [_vm._t("filename")],
              2
            ),
            _c(
              "div",
              { staticClass: "file-container" },
              [
                _c(
                  "VueFile",
                  { attrs: { pug: _vm.isPug } },
                  [
                    _c("template", { slot: "vuepug" }, [_vm._t("pug")], 2),
                    _c("template", { slot: "vuehtml" }, [_vm._t("html")], 2),
                    _vm.$slots.script
                      ? _c(
                          "template",
                          { slot: "vuescript" },
                          [_vm._t("script")],
                          2
                        )
                      : _vm._e()
                  ],
                  2
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "name-container" },
              [_vm._t("filename2")],
              2
            ),
            _c(
              "div",
              { staticClass: "file-container" },
              [
                _vm.$slots.pug2
                  ? _c(
                      "VueFile",
                      { attrs: { pug: _vm.isPug } },
                      [
                        _c("template", { slot: "vuepug" }, [_vm._t("pug2")], 2),
                        _c(
                          "template",
                          { slot: "vuehtml" },
                          [_vm._t("html2")],
                          2
                        ),
                        _vm.$slots.script2
                          ? _c(
                              "template",
                              { slot: "vuescript" },
                              [_vm._t("script2")],
                              2
                            )
                          : _vm._e()
                      ],
                      2
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }