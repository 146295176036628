var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    [
      _c(
        "Controls",
        { attrs: { inline: true, route: "/home/fullscreen" } },
        [
          _c("Example"),
          _c("h5", { attrs: { slot: "filename" }, slot: "filename" }, [
            _vm._v("logo.vue")
          ]),
          _c("pre", { attrs: { slot: "pug" }, slot: "pug" }, [
            _c("code", { attrs: { lang: "pug" } }, [
              _c("span", { staticClass: "token tag" }, [_vm._v("Scene")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Property"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("name")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"clearColor"')
                    ]),
                    _vm._v(" color"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"#FFF"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Camera"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("type")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"arcRotate"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("HemisphericLight")
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("property"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("name")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"intensity"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("float"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [_vm._v('"2"')])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Entity"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _vm._v(":"),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("scaling")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token number" }, [_vm._v("5")])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "token attr-name" }, [_vm._v("5")]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(' 5]"'),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Animation"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("property")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"rotation.y"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("duration"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"5"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("end"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"-Math.PI * 2"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [_vm._v("Side")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Entity"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _vm._v(":"),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("scaling")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("-")
                    ]),
                    _c("span", { staticClass: "token number" }, [_vm._v("1")])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "token attr-name" }, [_vm._v("1")]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(' 1]"'),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [_vm._v("Side")]),
              _vm._v("\n")
            ])
          ]),
          _c("pre", { attrs: { slot: "html" }, slot: "html" }, [
            _c("code", { attrs: { lang: "markup" } }, [
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Scene")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Property")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("name")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("clearColor"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("color")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("#FFF"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Property")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Camera")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("type")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("arcRotate"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Camera")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("HemisphericLight")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("property")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("name")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("intensity"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":float")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("2"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("property")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("HemisphericLight")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Entity")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":scaling")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("[5, 5, 5]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Animation")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("property")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("rotation.y"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":duration")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("5"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":end")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("-Math.PI * 2"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Animation")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Side")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Side")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Entity")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":scaling")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("[-1, 1, 1]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Side")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Side")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Entity")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Entity")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Scene")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ])
            ])
          ]),
          _c("h5", { attrs: { slot: "filename2" }, slot: "filename2" }, [
            _vm._v("side.vue")
          ]),
          _c("pre", { attrs: { slot: "pug2" }, slot: "pug2" }, [
            _c("code", { attrs: { lang: "pug" } }, [
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Entity"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _vm._v(":"),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("rotation")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("-")
                    ]),
                    _vm._v("Math"),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(".")
                    ]),
                    _c("span", { staticClass: "token constant" }, [
                      _vm._v("PI")
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("/")
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token number" }, [_vm._v("2")])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "token attr-name" }, [_vm._v("0")]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(' 0]" :'),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("position")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token number" }, [_vm._v("0")])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "token attr-name" }, [_vm._v("0")]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(' -0.15]"'),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("ExtrudePolygon"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _vm._v(":"),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("options")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("{")
                    ]),
                    _vm._v(" shape"),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token function" }, [
                      _vm._v("$vector")
                    ]),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("(")
                    ]),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token number" }, [
                      _vm._v("0.133")
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "token attr-name" }, [_vm._v("0")]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" 0.5]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v(", $vector([0"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _vm._v("346, 0, 0"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _vm._v("5]), $vector([0, 0, "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("-")]),
              _vm._v("0"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _vm._v("1]), $vector([0, 0, 0"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _vm._v("269])], depth: 0"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _vm._v("3, sideOrientation: BABYLON"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _vm._v("Mesh"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _vm._v('DOUBLESIDE }")\n    '),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Material"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("diffuse")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"#34495E"')
                    ]),
                    _vm._v(" specular"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"#34495E"')
                    ]),
                    _vm._v(" ")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("ExtrudePolygon"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _vm._v(":"),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("options")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("{")
                    ]),
                    _vm._v(" shape"),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token function" }, [
                      _vm._v("$vector")
                    ]),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("(")
                    ]),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token number" }, [
                      _vm._v("0.346")
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "token attr-name" }, [_vm._v("0")]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" 0.5]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v(", $vector([0"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _vm._v("577, 0, 0"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _vm._v("5]), $vector([0, 0, "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("-")]),
              _vm._v("0"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _vm._v("5]), $vector([0, 0, "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("-")]),
              _vm._v("0"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _vm._v("1])], depth: 0"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _vm._v("3, sideOrientation: BABYLON"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _vm._v("Mesh"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _vm._v('DOUBLESIDE }")\n    '),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Material"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("diffuse")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"#41B883"')
                    ]),
                    _vm._v(" specular"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"#41B883"')
                    ]),
                    _vm._v(" ")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n")
            ])
          ]),
          _c("pre", { attrs: { slot: "html2" }, slot: "html2" }, [
            _c("code", { attrs: { lang: "markup" } }, [
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Entity")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":rotation")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("[-Math.PI / 2, 0, 0]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":position")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("[0, 0, -0.15]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("ExtrudePolygon")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":options")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v(
                    "{ shape: [$vector([0.133, 0, 0.5]), $vector([0.346, 0, 0.5]), $vector([0, 0, -0.1]), $vector([0, 0, 0.269])], depth: 0.3, sideOrientation: BABYLON.Mesh.DOUBLESIDE }"
                  ),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Material")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("diffuse")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("#34495E"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("specular")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("#34495E"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Material")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("ExtrudePolygon")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("ExtrudePolygon")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":options")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v(
                    "{ shape: [$vector([0.346, 0, 0.5]), $vector([0.577, 0, 0.5]), $vector([0, 0, -0.5]), $vector([0, 0, -0.1])], depth: 0.3, sideOrientation: BABYLON.Mesh.DOUBLESIDE }"
                  ),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Material")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("diffuse")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("#41B883"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("specular")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("#41B883"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Material")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("ExtrudePolygon")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Entity")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ])
            ])
          ])
        ],
        1
      ),
      _c("h2", [
        _vm._v(
          "Vue-BabylonJS helps you create high quality 3D graphics in the web."
        )
      ]),
      _vm._v(" "),
      _c("h2", [_vm._v("It's as easy as writing HTML and CSS.")]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("h4", [
        _vm._v(
          "Vue-BabylonJS is a powerful tool. You can create 3D graphics featuring:"
        )
      ]),
      _vm._v(" "),
      _c("ul", [
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/physics" } }, [
              _vm._v("Physics")
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/light" } }, [
              _vm._v("Lighting Properties")
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/camera" } }, [_vm._v("Cameras")])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/texture" } }, [
              _vm._v("Textures")
            ]),
            _vm._v(" or "),
            _c("router-link", { attrs: { to: "/material" } }, [
              _vm._v("Materials")
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c("li", [_vm._v("More (See Sidebar)")])
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("h2", [_vm._v("Installation:")]),
      _vm._v(" "),
      _c(
        "p",
        [
          _c("router-link", { attrs: { to: "/installation" } }, [
            _vm._v("See instructions page")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("h2", [_vm._v("Usage:")]),
      _vm._v(" "),
      _c("h2", [_vm._v("Important things to know")]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _c(
            "strong",
            [
              _vm._v(
                "All components of this library must be a descendant of the "
              ),
              _c("router-link", { attrs: { to: "/scene" } }, [
                _vm._v("Scene Component")
              ]),
              _vm._v(".")
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "Many components expect to be a child of specific components defined by this plugin, check the API documentation of the component to see what its requirements are"
          )
        ])
      ]),
      _vm._v(" "),
      _c("h3", [_vm._v("Types for props (attributes)")]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "The following BabylonJS types have specific helpers in this plugin:"
        )
      ]),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _c(
        "p",
        [
          _c("router-link", { attrs: { to: "/types" } }, [
            _vm._v("See the documentation for Types for more information")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("h3", [_vm._v("Retrieving BabylonJS Objects")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Most components in this plugin support Vue's model-binding syntax."
        )
      ]),
      _vm._v(" "),
      _c("h4", [_vm._v("Notes")]),
      _vm._v(" "),
      _vm._m(4),
      _vm._v(" "),
      _c("h4", [_vm._v("Example")]),
      _vm._v(" "),
      _c("h5", [_vm._v("Template:")]),
      _vm._v(" "),
      _vm._m(5),
      _vm._v(" "),
      _c("h5", [_vm._v("Script:")]),
      _vm._v(" "),
      _vm._m(6),
      _vm._v(" "),
      _c(
        "p",
        [
          _c("router-link", { attrs: { to: "/entity" } }, [
            _vm._v("See the Entity documentation for more information")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", [_c("strong", [_vm._v("Get Started")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [
        _vm._v("Vue bindings are important when using this plugin")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("Always use "),
      _c("code", [_vm._v("v-bind:property")]),
      _vm._v(" or the shorthand "),
      _c("code", [_vm._v(":property")]),
      _vm._v(" on attributes to components that expect non-string values!")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("code", [_vm._v("Color3")]),
        _vm._v(" and "),
        _c("code", [_vm._v("Color4")]),
        _vm._v(" as a unified "),
        _c("code", [_vm._v("Color")]),
        _vm._v(" API")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("Vector2")]),
        _vm._v(", "),
        _c("code", [_vm._v("Vector3")]),
        _vm._v(", and "),
        _c("code", [_vm._v("Vector4")]),
        _vm._v(" as a unified "),
        _c("code", [_vm._v("Vector")]),
        _vm._v(" API")
      ]),
      _vm._v(" "),
      _c("li", [_c("code", [_vm._v("Matrix")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v("Use the attribute "),
        _c("code", [_vm._v("v-model")]),
        _vm._v(" on components")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("v-model")]),
        _vm._v(" does not require binding syntax")
      ]),
      _vm._v(" "),
      _c("li", [_vm._v("bind it to a property in your data object")]),
      _vm._v(" "),
      _c("li", [
        _vm._v("Use "),
        _c("code", [_vm._v("null")]),
        _vm._v(" for your initial value in your data object")
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "The model will not be immediately available, so do not expect populated data in lifecycle hooks"
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Use a watch function to be notified when the data property is populated and initeract with the object from there"
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v("The "),
        _c("code", [_vm._v("v-model")]),
        _vm._v(
          " value on some components may change during the lifecycle of the scene, so plan your watchers accordingly"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("pre", { staticClass: "language-html" }, [
      _c("code", { staticClass: "language-html" }, [
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Scene")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v("v-model")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("myScene"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n  "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Entity")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v("v-model")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("myEntity"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n    "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Box")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v("v-model")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("myBox"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Box")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n  "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Entity")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n"),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Scene")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("pre", { staticClass: "language-js" }, [
      _c("code", { staticClass: "language-js" }, [
        _vm._v("module"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
        _vm._v("exports "),
        _c("span", { staticClass: "token operator" }, [_vm._v("=")]),
        _vm._v(" "),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
        _vm._v("\n  "),
        _c("span", { staticClass: "token function" }, [_vm._v("data")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
        _vm._v(" "),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
        _vm._v("\n    "),
        _c("span", { staticClass: "token keyword" }, [_vm._v("return")]),
        _vm._v(" "),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
        _vm._v("\n      myScene"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(":")]),
        _vm._v(" "),
        _c("span", { staticClass: "token keyword" }, [_vm._v("null")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
        _vm._v("\n      myEntity"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(":")]),
        _vm._v(" "),
        _c("span", { staticClass: "token keyword" }, [_vm._v("null")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
        _vm._v("\n      myBox"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(":")]),
        _vm._v(" "),
        _c("span", { staticClass: "token keyword" }, [_vm._v("null")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
        _vm._v("\n    "),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
        _vm._v("\n  "),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
        _vm._v("\n\n  watch"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(":")]),
        _vm._v(" "),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
        _vm._v("\n    "),
        _c("span", { staticClass: "token function" }, [_vm._v("myScene")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
        _vm._v(" "),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
        _vm._v("\n      "),
        _c("span", { staticClass: "token comment" }, [
          _vm._v("// myScene is now available from the component")
        ]),
        _vm._v("\n      "),
        _c("span", { staticClass: "token comment" }, [
          _vm._v(
            "// do something with it here or call a method to use it from here"
          )
        ]),
        _vm._v("\n    "),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
        _vm._v("\n\n    "),
        _c("span", { staticClass: "token function" }, [_vm._v("myEntity")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
        _vm._v(" "),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
        _vm._v("\n      "),
        _c("span", { staticClass: "token comment" }, [
          _vm._v("// myEntity is now available from the component")
        ]),
        _vm._v("\n      "),
        _c("span", { staticClass: "token comment" }, [
          _vm._v(
            "// do something with it here or call a method to use it from here"
          )
        ]),
        _vm._v("\n    "),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
        _vm._v("\n\n    "),
        _c("span", { staticClass: "token function" }, [_vm._v("myBox")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
        _vm._v(" "),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
        _vm._v("\n      "),
        _c("span", { staticClass: "token comment" }, [
          _vm._v("// myBox is now available from the component")
        ]),
        _vm._v("\n      "),
        _c("span", { staticClass: "token comment" }, [
          _vm._v(
            "// do something with it here or call a method to use it from here"
          )
        ]),
        _vm._v("\n    "),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
        _vm._v("\n  "),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
        _vm._v("\n"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
        _vm._v("\n")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }