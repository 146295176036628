var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Scene",
    [
      _c("Camera", { attrs: { type: "arcRotate" } }),
      _c(
        "Entity",
        [
          _c("Animation", {
            attrs: { property: "rotation.y", duration: 5, end: Math.PI * 2 }
          }),
          _c("DirectionalLight", {
            attrs: { specular: "#0F0", diffuse: "F00", direction: [0, 0, 100] }
          })
        ],
        1
      ),
      _c(
        "Box",
        { attrs: { position: [-2, 0, 0] } },
        [_c("Material", { attrs: { diffuse: "#ffffff" } })],
        1
      ),
      _c(
        "IcoSphere",
        { attrs: { position: [1.3, 0, 0] } },
        [
          _c("Material", { attrs: { diffuse: "#FF0" } }),
          _c("Animation", {
            attrs: { property: "rotation.x", duration: 3, end: Math.PI * 2 }
          }),
          _c("Animation", {
            attrs: { property: "rotation.y", duration: 3, end: Math.PI * 2 }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }