var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Scene",
    [
      _c("Camera", { attrs: { type: "arcRotate" } }),
      _c("Box", { attrs: { scaling: [0.5, 1, 1], position: [2, 0, 0] } }),
      _c("Sphere", {
        attrs: { properties: { position: _vm.$vector([-2, 0, 0]) } }
      }),
      _c(
        "Cylinder",
        [
          _c("property", {
            attrs: { name: "scaling", vector: { x: 2, y: 0.5, z: 1 } }
          })
        ],
        1
      ),
      _c(
        "Entity",
        [
          _c("Animation", {
            attrs: { property: "rotation.x", duration: 5, end: Math.PI * 2 }
          }),
          _c("PointLight", { attrs: { diffuse: "#F0F", position: [0, 0, 4] } })
        ],
        1
      ),
      _c(
        "Entity",
        [
          _c("Animation", {
            attrs: { property: "rotation.y", duration: 5, end: Math.PI * 2 }
          }),
          _c(
            "PointLight",
            [
              _c("property", { attrs: { name: "diffuse", color: "#0FF" } }),
              _c("property", { attrs: { name: "position", vector: [0, 0, 4] } })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Entity",
        [
          _c("Animation", {
            attrs: { property: "rotation.z", duration: 5, end: Math.PI * 2 }
          }),
          _c(
            "PointLight",
            {
              attrs: {
                properties: {
                  diffuse: _vm.$color("#FF0"),
                  specular: _vm.$color({ r: 1, g: 0, b: 0 })
                }
              }
            },
            [
              _c("property", { attrs: { name: "position", vector: [0, 4, 0] } })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }