var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    [
      _c("h2", [_vm._v("Property")]),
      _c("h4", [_vm._v("Example")]),
      _c(
        "Controls",
        { attrs: { inline: true, route: "/property/fullscreen" } },
        [
          _c("Example"),
          _c("h5", { attrs: { slot: "filename" }, slot: "filename" }, [
            _vm._v("properties.vue")
          ]),
          _c("pre", { attrs: { slot: "pug" }, slot: "pug" }, [
            _c("code", { attrs: { lang: "pug" } }, [
              _c("span", { staticClass: "token tag" }, [_vm._v("Scene")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Camera"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("type")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"arcRotate"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n\n  "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("// Standard Mesh component props")
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Box"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _vm._v(":"),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("scaling")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token number" }, [_vm._v("0.5")])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "token attr-name" }, [_vm._v("1")]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(' 1]" :'),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("position")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token number" }, [_vm._v("2")])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "token attr-name" }, [_vm._v("0")]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(' 0]"'),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n\n  "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("// Properties prop object on Entity component")
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("// $vector() helper function")
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Sphere"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _vm._v(":"),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("properties")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("{")
                    ]),
                    _vm._v(" position"),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token function" }, [
                      _vm._v("$vector")
                    ]),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("(")
                    ]),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("-")
                    ]),
                    _c("span", { staticClass: "token number" }, [_vm._v("2")])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "token attr-name" }, [_vm._v("0")]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" 0]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "token plain-text" }, [_vm._v('}")')]),
              _vm._v("\n\n  "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("// Property component")
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [_vm._v("Cylinder")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("property"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("name")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"scaling"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("vector"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("{")
                    ]),
                    _vm._v(" x"),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token number" }, [_vm._v("2")])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" y: 0."),
                  _c("span", { staticClass: "token attr-name" }, [_vm._v("5")]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(' z: 1 }"'),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n\n  "),
              _c("span", { staticClass: "token tag" }, [_vm._v("Entity")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Animation"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("property")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"rotation.x"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("duration"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"5"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("end"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"Math.PI * 2"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("// Standard Light component props")
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("PointLight"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("diffuse")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"#F0F"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("position"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token number" }, [_vm._v("0")])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "token attr-name" }, [_vm._v("0")]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(' 4]"'),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n\n  "),
              _c("span", { staticClass: "token tag" }, [_vm._v("Entity")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Animation"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("property")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"rotation.y"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("duration"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"5"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("end"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"Math.PI * 2"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("// Property components")
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [_vm._v("PointLight")]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("property"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("name")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"diffuse"')
                    ]),
                    _vm._v(" color"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"#0FF"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("property"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("name")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"position"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("vector"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token number" }, [_vm._v("0")])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "token attr-name" }, [_vm._v("0")]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(' 4]"'),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n\n  "),
              _c("span", { staticClass: "token tag" }, [_vm._v("Entity")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Animation"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("property")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"rotation.z"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("duration"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"5"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("end"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"Math.PI * 2"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v(
                  "// Properties prop object on Entity component (set multiple values!)"
                )
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("// $color() helper function")
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("PointLight"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _vm._v(":"),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("properties")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("{")
                    ]),
                    _vm._v(" diffuse"),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token function" }, [
                      _vm._v("$color")
                    ]),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("(")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v("'#FF0'")
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v(', specular: $color({ r: 1, g: 0, b: 0 }) }")\n      '),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("// Mix-and-match!")
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("property"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("name")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"position"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("vector"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token number" }, [_vm._v("0")])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "token attr-name" }, [_vm._v("4")]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(' 0]"'),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n\n"),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("// Also available:")
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token comment" }, [
                _vm._v('// float="..." Property component prop')
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token comment" }, [
                _vm._v('// matrix="..." Property component prop')
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token comment" }, [
                _vm._v('// any="..." Property component prop')
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("// $matrix() helper function")
              ]),
              _vm._v("\n")
            ])
          ]),
          _c("pre", { attrs: { slot: "html" }, slot: "html" }, [
            _c("code", { attrs: { lang: "markup" } }, [
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Scene")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Camera")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("type")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("arcRotate"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Camera")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("<!-- Standard Mesh component props-->")
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Box")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":scaling")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("[0.5, 1, 1]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":position")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("[2, 0, 0]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Box")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("<!-- Properties prop object on Entity component-->")
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("<!-- $vector() helper function-->")
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Sphere")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":properties")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("{ position: $vector([-2, 0, 0]) }"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Sphere")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("<!-- Property component-->")
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Cylinder")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("property")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("name")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("scaling"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":vector")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("{ x: 2, y: 0.5, z: 1 }"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("property")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Cylinder")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Entity")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Animation")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("property")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("rotation.x"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":duration")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("5"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":end")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("Math.PI * 2"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Animation")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("<!-- Standard Light component props-->")
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("PointLight")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("diffuse")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("#F0F"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":position")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("[0, 0, 4]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("PointLight")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Entity")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Entity")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Animation")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("property")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("rotation.y"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":duration")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("5"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":end")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("Math.PI * 2"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Animation")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("<!-- Property components-->")
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("PointLight")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("property")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("name")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("diffuse"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("color")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("#0FF"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("property")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("property")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("name")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("position"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":vector")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("[0, 0, 4]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("property")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("PointLight")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Entity")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Entity")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Animation")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("property")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("rotation.z"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":duration")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("5"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":end")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("Math.PI * 2"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Animation")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v(
                  "<!-- Properties prop object on Entity component (set multiple values!)-->"
                )
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("<!-- $color() helper function-->")
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("PointLight")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":properties")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v(
                    "{ diffuse: $color('#FF0'), specular: $color({ r: 1, g: 0, b: 0 }) }"
                  ),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("<!-- Mix-and-match!-->")
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("property")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("name")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("position"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":vector")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("[0, 4, 0]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("property")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("PointLight")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Entity")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Scene")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("<!-- Also available:-->")
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token comment" }, [
                _vm._v('<!-- float="..." Property component prop-->')
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token comment" }, [
                _vm._v('<!-- matrix="..." Property component prop-->')
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token comment" }, [
                _vm._v('<!-- any="..." Property component prop-->')
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("<!-- $matrix() helper function-->")
              ])
            ])
          ])
        ],
        1
      ),
      _c("h4", [_vm._v("Description")]),
      _vm._v(" "),
      _c("p", [
        _vm._v("Change a value in an Entity's underlying BabylonJS object")
      ]),
      _vm._v(" "),
      _c("h4", [_vm._v("Details")]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("h4", [_vm._v("Usage")]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("h4", [_vm._v("Props")]),
      _vm._v(" "),
      _vm._m(2)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v(
          "This system was developed to allow for manipulation to properties outside the scope of this Plugin"
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "This plugin will not specify every possible property for every class in BabylonJS, use this Property system or obtain a reference to the underlying BabylonJS object in your Vue component script and consult the BabylonJS API documentation"
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "These values are reactive, but check the BabylonJS class for the object you are manipulating to be sure this will actually change in the Scene"
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v("Use only one of the "),
        _c("code", [_vm._v("any")]),
        _vm._v(", "),
        _c("code", [_vm._v("float")]),
        _vm._v(", "),
        _c("code", [_vm._v("color")]),
        _vm._v(", "),
        _c("code", [_vm._v("vector")]),
        _vm._v(", or "),
        _c("code", [_vm._v("matrix")]),
        _vm._v(" props (attributes)")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("pre", { staticClass: "language-html" }, [
      _c("code", { staticClass: "language-html" }, [
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Scene")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n  "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Property")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v("name")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("someSceneProperty"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v(":any")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("{ aProperty: 'a value' }"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Property")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n"),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Scene")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("code", [_vm._v("name")]),
        _vm._v(" (String) - the name of the property to set")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("any")]),
        _vm._v(" (any) - a value of any type to set")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("float")]),
        _vm._v(" (Number) - a number value to set")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("color")]),
        _vm._v(" (Color) - a Color from a helper or BabylonJS to set")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("vector")]),
        _vm._v(" (Vector) - a Vector from a helper or BabylonJS to set")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("matrix")]),
        _vm._v(" (Matrix) - a Matrix from a helper or BabylonJS to set")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }