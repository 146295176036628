var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Scene",
    { on: { complete: _vm.complete } },
    [
      _c("Camera", {
        attrs: { type: "arcRotate" },
        on: { entity: _vm.onCamera }
      }),
      _c("Spotlight", { attrs: { position: [0, 5, 0], specular: "#FF0000" } }),
      _c("Ground", {
        attrs: { position: [0, 1, 0], options: { width: 10, height: 10 } }
      }),
      _c(
        "Plane",
        { attrs: { options: { width: 20, height: 20 } } },
        [_c("Material", { attrs: { specular: "#FF0" } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }