var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Scene",
    [
      _c("Camera", { attrs: { type: "arcRotate", radius: 20 } }),
      _c("DirectionalLight", {
        model: {
          value: _vm.myLight,
          callback: function($$v) {
            _vm.myLight = $$v
          },
          expression: "myLight"
        }
      }),
      _c("HemisphericLight", { attrs: { emissive: "#008800" } }),
      _c("PointLight", { attrs: { specular: "#0F0" } }),
      _c(
        "Entity",
        [
          _vm._v('(v-model="myEntity2" :position="[6, 0, 0]")'),
          _c("Box", { attrs: { position: [-3, 3, 3] } }),
          _c("Box", { attrs: { position: [-9, 3, 3] } }),
          _c("Box", { attrs: { position: [-3, -3, 3] } }),
          _c("Box", { attrs: { position: [-9, -3, 3] } }),
          _c("Box", { attrs: { position: [-3, 3, -3] } }),
          _c("Box", { attrs: { position: [-3, -3, -3] } }),
          _c("Box", { attrs: { position: [-9, 3, -3] } }),
          _c("Box", { attrs: { position: [-9, -3, -3] } }),
          _c(
            "Entity",
            [
              _vm._v('(v-model="myEntity21" :position="[6, 0, 0]")'),
              _c(
                "Animation",
                { attrs: { property: "rotation.x", duration: 5 } },
                [
                  _c("Key", { attrs: { frame: "0%", value: 0 } }),
                  _c("Key", { attrs: { frame: "100%", value: Math.PI * 2 } })
                ],
                1
              ),
              _c(
                "Box",
                { attrs: { position: [-4, 2, 2] } },
                [_c("Material", { attrs: { diffuse: "#00F" } })],
                1
              ),
              _c(
                "Box",
                { attrs: { position: [-4, -2, 2] } },
                [_c("Material", { attrs: { diffuse: "#00F" } })],
                1
              ),
              _c(
                "Box",
                { attrs: { position: [-8, 2, 2] } },
                [_c("Material", { attrs: { diffuse: "#00F" } })],
                1
              ),
              _c(
                "Box",
                { attrs: { position: [-8, -2, 2] } },
                [_c("Material", { attrs: { diffuse: "#00F" } })],
                1
              ),
              _c(
                "Box",
                { attrs: { position: [-4, 2, -2] } },
                [_c("Material", { attrs: { diffuse: "#00F" } })],
                1
              ),
              _c(
                "Box",
                { attrs: { position: [-4, -2, -2] } },
                [_c("Material", { attrs: { diffuse: "#00F" } })],
                1
              ),
              _c(
                "Box",
                { attrs: { position: [-8, 2, -2] } },
                [_c("Material", { attrs: { diffuse: "#00F" } })],
                1
              ),
              _c(
                "Box",
                { attrs: { position: [-8, -2, -2] } },
                [_c("Material", { attrs: { diffuse: "#00F" } })],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Entity",
        [
          _vm._v('(v-model="myEntity1" :position="[7, 0, 0]")'),
          _c(
            "Animation",
            { attrs: { property: "rotation.x", duration: 5 } },
            [
              _c("Key", { attrs: { frame: "0%", value: 0 } }),
              _c("Key", { attrs: { frame: "100%", value: Math.PI * 2 } })
            ],
            1
          ),
          _c("PointLight", { attrs: { diffuse: "#FF0000" } }),
          _c("Box", { attrs: { position: [10, 3, 3] } }),
          _c("Box", { attrs: { position: [4, 3, 3] } }),
          _c("Box", { attrs: { position: [10, -3, 3] } }),
          _c("Box", { attrs: { position: [4, -3, 3] } }),
          _c("Box", { attrs: { position: [10, 3, -3] } }),
          _c("Box", { attrs: { position: [4, -3, -3] } }),
          _c("Box", { attrs: { position: [4, 3, -3] } }),
          _c("Box", { attrs: { position: [10, -3, -3] } }),
          _c(
            "Entity",
            [
              _vm._v('(v-model="myEntity11" :position="[7, 0, 0]")'),
              _c(
                "Box",
                { attrs: { position: [9, 2, 2] } },
                [_c("Material", { attrs: { diffuse: "#00F" } })],
                1
              ),
              _c(
                "Box",
                { attrs: { position: [9, -2, 2] } },
                [_c("Material", { attrs: { diffuse: "#00F" } })],
                1
              ),
              _c(
                "Box",
                { attrs: { position: [5, 2, 2] } },
                [_c("Material", { attrs: { diffuse: "#00F" } })],
                1
              ),
              _c(
                "Box",
                { attrs: { position: [5, -2, 2] } },
                [_c("Material", { attrs: { diffuse: "#00F" } })],
                1
              ),
              _c(
                "Box",
                { attrs: { position: [9, 2, -2] } },
                [_c("Material", { attrs: { diffuse: "#00F" } })],
                1
              ),
              _c(
                "Box",
                { attrs: { position: [9, -2, -2] } },
                [_c("Material", { attrs: { diffuse: "#00F" } })],
                1
              ),
              _c(
                "Box",
                { attrs: { position: [5, 2, -2] } },
                [_c("Material", { attrs: { diffuse: "#00F" } })],
                1
              ),
              _c(
                "Box",
                { attrs: { position: [5, -2, -2] } },
                [_c("Material", { attrs: { diffuse: "#00F" } })],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }