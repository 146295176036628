var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    [
      _c("h2", [_vm._v("Animations")]),
      _c("h4", [_vm._v("Example")]),
      _c(
        "Controls",
        { attrs: { inline: true, route: "/animations/fullscreen" } },
        [
          _c("Example"),
          _c("h5", { attrs: { slot: "filename" }, slot: "filename" }, [
            _vm._v("animation.vue")
          ]),
          _c("pre", { attrs: { slot: "pug" }, slot: "pug" }, [
            _c("code", { attrs: { lang: "pug" } }, [
              _c("span", { staticClass: "token tag" }, [_vm._v("Scene")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [_vm._v("Camera")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("HemisphericLight"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("diffuse")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"#0000FF"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Entity"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _vm._v(":"),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("position")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token number" }, [_vm._v("0")])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "token attr-name" }, [_vm._v("0")]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(' 5]"'),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Animation"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("property")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"rotation.x"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("duration"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [_vm._v('"5"')])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Key"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("frame")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"0%"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("value"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [_vm._v('"0"')])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Key"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("frame")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"100%"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("value"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"Math.PI * 2"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Animation"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("property")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"rotation.y"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("duration"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"5"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("end"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"Math.PI * 2"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Animation"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("property")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"rotation.z"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("duration"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"5"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("end"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"Math.PI * 2"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("PointLight"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("diffuse")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"#FF0000"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("template"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("v-for")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _vm._v('"x '),
                    _c("span", { staticClass: "token keyword" }, [
                      _vm._v("in")
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token number" }, [_vm._v("0")])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "token attr-name" }, [_vm._v("4")]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(' -4]"'),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("template"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("v-for")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _vm._v('"y '),
                    _c("span", { staticClass: "token keyword" }, [
                      _vm._v("in")
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token number" }, [_vm._v("0")])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "token attr-name" }, [_vm._v("4")]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(' -4]"'),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Box"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("v-for")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _vm._v('"z '),
                    _c("span", { staticClass: "token keyword" }, [
                      _vm._v("in")
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token number" }, [_vm._v("0")])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "token attr-name" }, [_vm._v("4")]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(' -4]" :'),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("position")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _vm._v("x")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "token attr-name" }, [_vm._v("y")]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(' z]" :'),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("key")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _vm._v('"`$'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("{")
                    ]),
                    _vm._v("x"),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("}")
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v("${y}"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v('${z}`"'),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n")
            ])
          ]),
          _c("pre", { attrs: { slot: "html" }, slot: "html" }, [
            _c("code", { attrs: { lang: "markup" } }, [
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Scene")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Camera")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Camera")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("HemisphericLight")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("diffuse")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("#0000FF"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("HemisphericLight")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Entity")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":position")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("[0, 0, 5]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Animation")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("property")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("rotation.x"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":duration")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("5"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Key")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("frame")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("0%"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":value")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("0"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Key")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Key")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("frame")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("100%"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":value")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("Math.PI * 2"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Key")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Animation")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Animation")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("property")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("rotation.y"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":duration")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("5"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":end")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("Math.PI * 2"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Animation")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Animation")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("property")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("rotation.z"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":duration")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("5"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":end")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("Math.PI * 2"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Animation")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("PointLight")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("diffuse")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("#FF0000"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("PointLight")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("template")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("v-for")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("x in [0, 4, -4]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("template")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("v-for")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("y in [0, 4, -4]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Box")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("v-for")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("z in [0, 4, -4]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":position")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("[x, y, z]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":key")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("`${x},${y},${z}`"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Box")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("template")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("template")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Entity")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Scene")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ])
            ])
          ])
        ],
        1
      ),
      _c("h4", [_vm._v("Description")]),
      _vm._v(" "),
      _c("p", [
        _vm._v("This component will apply an animation to an Entity component")
      ]),
      _vm._v(" "),
      _c("h4", [_vm._v("Details")]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c("h4", [_vm._v("Usage")]),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _c("h4", [_vm._v("Props")]),
      _vm._v(" "),
      _vm._m(4),
      _vm._v(" "),
      _c("h3", [_vm._v("Key")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "This component represents a keyframe for an Animation component"
        )
      ]),
      _vm._v(" "),
      _c("h4", [_vm._v("Details")]),
      _vm._v(" "),
      _vm._m(5),
      _vm._v(" "),
      _c("h4", [_vm._v("Usage")]),
      _vm._v(" "),
      _vm._m(6),
      _vm._v(" "),
      _c("h4", [_vm._v("Props")]),
      _vm._v(" "),
      _vm._m(7)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("The "),
      _c(
        "a",
        { attrs: { href: "http://doc.babylonjs.com/babylon101/animations" } },
        [_vm._v("BabylonJS guide to animations")]
      ),
      _vm._v(" can be helpful here.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [_vm._v("Must be a child component of an existing Entity")]),
      _vm._v(" "),
      _c("li", [
        _vm._v("Properties are in the format "),
        _c("code", [_vm._v("myObject.myProperty")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("See "),
      _c(
        "a",
        {
          attrs: {
            href: "http://doc.babylonjs.com/api/classes/babylon.animation"
          }
        },
        [_vm._v("the BabylonJS api documentation on the Animation class")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("pre", { staticClass: "language-html" }, [
      _c("code", { staticClass: "language-html" }, [
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Scene")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n  "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Entity")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n    "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Animation")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v("v-model")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("myModel"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _vm._v("\n               "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v("property")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("position.x"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _vm._v("\n               "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v(":start")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("1"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _vm._v("\n               "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v(":end")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("10"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _vm._v("\n               "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v(":duration")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("5"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n    "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Animation")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n  "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Entity")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n"),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Scene")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("code", [_vm._v("type")]),
        _vm._v(
          " (String) - The type of property to animate, see below for valid values:\n"
        ),
        _c("ul", [
          _c("li", [_c("code", [_vm._v('"float"')])]),
          _vm._v(" "),
          _c("li", [_c("code", [_vm._v('"vector2"')])]),
          _vm._v(" "),
          _c("li", [_c("code", [_vm._v('"vector3"')])]),
          _vm._v(" "),
          _c("li", [_c("code", [_vm._v('"size"')])]),
          _vm._v(" "),
          _c("li", [_c("code", [_vm._v('"quaternion"')])]),
          _vm._v(" "),
          _c("li", [_c("code", [_vm._v('"matrix"')])]),
          _vm._v(" "),
          _c("li", [_c("code", [_vm._v('"color3"')])])
        ])
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("mode")]),
        _vm._v(
          " (String) - The loop mode of the animation, see below for valid values:\n"
        ),
        _c("ul", [
          _c("li", [_c("code", [_vm._v('"cycle"')])]),
          _vm._v(" "),
          _c("li", [_c("code", [_vm._v('"relative"')])]),
          _vm._v(" "),
          _c("li", [_c("code", [_vm._v('"constant"')])])
        ])
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("property")]),
        _vm._v(" (String) - Path to the property to animate with dots ("),
        _c("code", [_vm._v(".")]),
        _vm._v(")")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("fps")]),
        _vm._v(
          " (Number) - the frames-per-second of the animation (default 60)"
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("from")]),
        _vm._v(" (Number) - Frame number to begin animating on")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("to")]),
        _vm._v(" (Number) - Frame number to end animating on")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("duration")]),
        _vm._v(" (Number) - Length of the animation in seconds")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("start")]),
        _vm._v(
          " (Number) - Starting value of the property that is being animated"
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("end")]),
        _vm._v(
          " (Number) - Ending value of the property that is being animated"
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("loop")]),
        _vm._v(" (Boolean) - Whether to loop the animation (default true)")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("speedRatio")]),
        _vm._v(" (Number) - The speed ratio of this animation")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("animatable")]),
        _vm._v(" (Object) - A specific animation object from BabylonJS")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("blending")]),
        _vm._v(
          " (Boolean) - enable interpolation FROM the current object's state (default false)"
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("blendingSpeed")]),
        _vm._v(" (Number) - speed of blending interpolation")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("easing")]),
        _vm._v(
          " (String) - easing function name, see below for valid values:\n"
        ),
        _c("ul", [
          _c("li", [_c("code", [_vm._v('"circle"')])]),
          _vm._v(" "),
          _c("li", [_c("code", [_vm._v('"back"')])]),
          _vm._v(" "),
          _c("li", [_c("code", [_vm._v('"bounce"')])]),
          _vm._v(" "),
          _c("li", [_c("code", [_vm._v('"cubic"')])]),
          _vm._v(" "),
          _c("li", [_c("code", [_vm._v('"elastic"')])]),
          _vm._v(" "),
          _c("li", [_c("code", [_vm._v('"exponential"')])]),
          _vm._v(" "),
          _c("li", [_c("code", [_vm._v('"power"')])]),
          _vm._v(" "),
          _c("li", [_c("code", [_vm._v('"quadratic"')])]),
          _vm._v(" "),
          _c("li", [_c("code", [_vm._v('"quartic"')])]),
          _vm._v(" "),
          _c("li", [_c("code", [_vm._v('"quintic"')])]),
          _vm._v(" "),
          _c("li", [_c("code", [_vm._v('"sine"')])]),
          _vm._v(" "),
          _c("li", [_c("code", [_vm._v('"bezierCurve"')])])
        ])
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("easingMode")]),
        _vm._v(
          " (String) - mode of the easing function, see below for valid values:\n"
        ),
        _c("ul", [
          _c("li", [_c("code", [_vm._v('"in"')])]),
          _vm._v(" "),
          _c("li", [_c("code", [_vm._v('"out"')])]),
          _vm._v(" "),
          _c("li", [_c("code", [_vm._v('"inout"')])])
        ])
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("amplitude")]),
        _vm._v(" (Number) - For the "),
        _c("code", [_vm._v('"back"')]),
        _vm._v(" easing function")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("bounces")]),
        _vm._v(" (Number) - For the "),
        _c("code", [_vm._v('"bounce"')]),
        _vm._v(" easing function")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("bounciness")]),
        _vm._v(" (Number) - For the "),
        _c("code", [_vm._v('"bounce"')]),
        _vm._v(" easing function")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("oscillations")]),
        _vm._v(" (Number) - For the "),
        _c("code", [_vm._v('"elastic"')]),
        _vm._v(" easing function")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("springiness")]),
        _vm._v(" (Number) - For the "),
        _c("code", [_vm._v('"elastic"')]),
        _vm._v(" easing function")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("exponent")]),
        _vm._v(" (Number) - For the "),
        _c("code", [_vm._v('"exponential"')]),
        _vm._v(" easing function")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("power")]),
        _vm._v(" (Number) - For the "),
        _c("code", [_vm._v('"power"')]),
        _vm._v(" easing function")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("x1")]),
        _vm._v(" (Number) - For the "),
        _c("code", [_vm._v('"bezierCurve"')]),
        _vm._v(" easing function")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("y1")]),
        _vm._v(" (Number) - For the "),
        _c("code", [_vm._v('"bezierCurve"')]),
        _vm._v(" easing function")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("x2")]),
        _vm._v(" (Number) - For the "),
        _c("code", [_vm._v('"bezierCurve"')]),
        _vm._v(" easing function")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("y2")]),
        _vm._v(" (Number) - For the "),
        _c("code", [_vm._v('"bezierCurve"')]),
        _vm._v(" easing function")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v("Must be a child component of an existing Animation component")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("pre", { staticClass: "language-html" }, [
      _c("code", { staticClass: "language-html" }, [
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Scene")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n  "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Entity")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n    "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Animation")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v("property")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("position.x"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v(":duration")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("3"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n      "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Key")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v("frame")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("0%"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v(":value")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("1"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Key")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n      "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Key")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v("frame")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("20%"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v(":value")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("5"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Key")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n      "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Key")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v("frame")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("100%"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v(":value")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("10"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Key")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n    "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Animation")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n  "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Entity")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n"),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Scene")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("code", [_vm._v("frame")]),
        _vm._v(
          " (Number|String) - what frame number this keyframe represents or a string percentage ("
        ),
        _c("code", [_vm._v("XX%")]),
        _vm._v(") of the duration")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("value")]),
        _vm._v(" (any) - the value to set the property to at this keyframe")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("inTangent")]),
        _vm._v(" (Vector3) - Optional spline interpolation mode")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("outTangent")]),
        _vm._v(" (Vector3) - Optional spline interpolation mode")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }