var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Scene",
    [
      _c("Camera", { attrs: { type: "arcRotate" } }),
      _c(
        "Box",
        { attrs: { position: [2, 0, 0] } },
        [
          _c("Animation", {
            attrs: { property: "rotation.x", duration: 5, end: Math.PI * 2 }
          }),
          _c(
            "Shader",
            { attrs: { shader: _vm.shader } },
            [
              _c("Uniform", { attrs: { variable: "color", color: "#F00" } }),
              _c("Uniform", { attrs: { variable: "start", float: 0.5 } })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Box",
        { attrs: { position: [0, -2, 0] } },
        [
          _c("Animation", {
            attrs: { property: "rotation.y", duration: 5, end: Math.PI * 2 }
          }),
          _c(
            "Shader",
            { attrs: { vertex: _vm.shader, fragment: _vm.shader } },
            [
              _c("Uniform", { attrs: { variable: "color", color: "#0F0" } }),
              _c("Uniform", { attrs: { variable: "start", float: 1 } })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Box",
        { attrs: { position: [-2, 0, 0] } },
        [
          _c("Animation", {
            attrs: { property: "rotation.x", duration: 5, end: -Math.PI * 2 }
          }),
          _c(
            "Shader",
            {
              attrs: {
                name: "inline",
                vertexShader: _vm.vertexShader,
                fragmentShader: _vm.fragmentShader
              }
            },
            [
              _c("Uniform", { attrs: { variable: "color", color: "#00F" } }),
              _c("Uniform", { attrs: { variable: "start", float: -1 } })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Box",
        { attrs: { position: [0, 2, 0] } },
        [
          _c("Animation", {
            attrs: { property: "rotation.y", duration: 5, end: -Math.PI * 2 }
          }),
          _c(
            "Shader",
            [
              _c("Vertex", {
                attrs: { name: "namedvertex", content: _vm.vertexShader }
              }),
              _c("Fragment", {
                attrs: { name: "namedfragment", content: _vm.fragmentShader }
              }),
              _c("Uniform", { attrs: { variable: "color", color: "#FF0" } }),
              _c("Uniform", { attrs: { variable: "start", float: -0.5 } })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Box",
        [
          _c("Animation", {
            attrs: { property: "rotation.x", duration: 5, end: Math.PI * 2 }
          }),
          _c("Animation", {
            attrs: { property: "rotation.y", duration: 5, end: Math.PI * 2 }
          }),
          _c("Animation", {
            attrs: { property: "rotation.z", duration: 5, end: Math.PI * 2 }
          }),
          _c(
            "Shader",
            { attrs: { name: "text" } },
            [
              _c("Vertex", [
                _vm._v(
                  "precision highp float;\nattribute vec3 position;\nuniform mat4 worldViewProjection;\nuniform float time;\nvarying vec3 direction;\n\nvoid main() {\n  gl_Position = worldViewProjection * vec4(position, 1.0);\n  direction = position;\n}\n"
                )
              ]),
              _c("Fragment", [
                _vm._v(
                  "precision highp float;\nuniform float time;\nvarying vec3 direction;\n\nvoid main() {\n  gl_FragColor = vec4(cos(time / 1000.0) * direction, 1.0);\n}\n"
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }