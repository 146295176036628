var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("article", [
      _c("h2", [_vm._v("About")]),
      _c("div", { staticClass: "frow gutters" }, [
        _c("div", { staticClass: "col-md-1-2" }, [
          _c("div", { staticClass: "frow" }, [
            _c("img", {
              staticClass: "founder-image",
              attrs: { src: require("./assets/brian.jpg"), alt: "Brian" }
            })
          ]),
          _c("div", { staticClass: "founder-name" }, [_vm._v("Brian Jesse")]),
          _c(
            "a",
            {
              staticClass: "founder-twitter",
              attrs: { href: "https://twitter.com/Brain_Bacon" }
            },
            [_vm._v("@Brain_Bacon")]
          ),
          _c("div", { staticClass: "founder-title" }, [_vm._v("Founder")]),
          _c("div", { staticClass: "links" }, [
            _c(
              "a",
              { attrs: { href: "https://github.com/Beg-in/vue-babylonjs)" } },
              [_vm._v("GitHub")]
            ),
            _c(
              "a",
              {
                attrs: {
                  href: "https://github.com/Beg-in/vue-babylonjs/issues/1"
                }
              },
              [_vm._v("Updates / Newsletter")]
            ),
            _c(
              "a",
              {
                attrs: {
                  href:
                    "https://github.com/Beg-in/vue-babylonjs/blob/master/CHANGELOG.md"
                }
              },
              [_vm._v("Changelog")]
            ),
            _c(
              "a",
              {
                attrs: {
                  href:
                    "https://github.com/Beg-in/vue-babylonjs/blob/master/CONTRIBUTING.md"
                }
              },
              [_vm._v("Contributing")]
            ),
            _c("a", { attrs: { href: "https://beg.in/" } }, [
              _vm._v("From Begin")
            ])
          ])
        ]),
        _c("div", { staticClass: "col-md-1-2" }, [
          _c("h4", [_vm._v("What?")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Vue-BabylonJS is a 3D graphics component plugin for Vue.js powered by BabylonJS. Vue-BabylonJS draws inspiration from A-Frame, but can be more performant with the exclusion of DOM manipulation and has closer ties to JavaScript through property binding syntax in Vue. Compared to ReactVR which uses A-Frame, Vue-BabylonJS has the potential for higher performance, more organized and decoupled components, and a higher-quality rendering engine."
            )
          ]),
          _vm._v(" "),
          _c("h4", [_vm._v("Why?")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "We use BabylonJS because it is the most efficient, most feature-rich, and most modern WebGL graphics library available. The addition of Vue makes the engine reactive and development becomes easier to reason about and organize. Out-of-the-box mobile support and sensible defaults make getting started a breeze."
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "The underlying engine is easily accessible to give pros the tools to tweak every aspect of BabylonJS. The organizational structure of the library is a Component-Entity-System and the Entity component contains many powerful features such a matrix transformation to allow for interaction with the Scene graph like a group of HTML divs. Powerful tools are available such as an integrated reactive property system that enables modifying 3D objects within templates and a Shader component that makes adding WebGL shaders easy."
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }