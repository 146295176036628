var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vuefile" }, [
    _c(
      "code",
      {
        directives: [
          { name: "show", rawName: "v-show", value: _vm.pug, expression: "pug" }
        ]
      },
      [_vm._m(0)]
    ),
    _c(
      "code",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.pug,
            expression: "!pug"
          }
        ]
      },
      [_vm._m(1)]
    ),
    _c(
      "code",
      {
        directives: [
          { name: "show", rawName: "v-show", value: _vm.pug, expression: "pug" }
        ]
      },
      [_vm._t("vuepug")],
      2
    ),
    _c(
      "code",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.pug,
            expression: "!pug"
          }
        ]
      },
      [_vm._t("vuehtml")],
      2
    ),
    _vm._m(2),
    _vm.$slots.vuescript ? _c("code", [_c("br"), _vm._m(3)]) : _vm._e(),
    _vm.$slots.vuescript ? _c("code", [_vm._t("vuescript")], 2) : _vm._e(),
    _vm.$slots.vuescript ? _c("code", [_vm._m(4)]) : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("code", { attrs: { lang: "markup" } }, [
      _c("span", { staticClass: "token tag" }, [
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
          _vm._v("template")
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "token attr-name" }, [_vm._v("lang")]),
        _c("span", { staticClass: "token attr-value" }, [
          _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
          _vm._v("pug"),
          _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
        ]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("code", { attrs: { lang: "markup" } }, [
      _c("span", { staticClass: "token tag" }, [
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
          _vm._v("template")
        ]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("code", { attrs: { lang: "markup" } }, [
      _c("span", { staticClass: "token tag" }, [
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
          _vm._v("template")
        ]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("code", { attrs: { lang: "markup" } }, [
      _c("span", { staticClass: "token tag" }, [
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
          _vm._v("script")
        ]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("code", { attrs: { lang: "markup" } }, [
      _c("span", { staticClass: "token tag" }, [
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
          _vm._v("script")
        ]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }