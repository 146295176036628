var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Scene",
    [
      _c("Camera", { attrs: { type: "arcRotate" } }),
      _c("PointLight", {
        attrs: { position: [0, 0.5, 0], specular: "#FF0000" }
      }),
      _c(
        "IcoSphere",
        { attrs: { position: [-3.3, 1, 0] } },
        [_c("Material", { attrs: { diffuse: "#FFFF33" } })],
        1
      ),
      _c(
        "IcoSphere",
        { attrs: { position: [3.3, 1, 0] } },
        [_c("Material", { attrs: { diffuse: "#7FFF00" } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }