var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Scene",
    [
      _c("Camera", {
        attrs: { type: "arcRotate", radius: 7.5, beta: Math.PI / 3 }
      }),
      _c("HemisphericLight"),
      _c("Asset", {
        attrs: {
          src: "https://www.babylonjs-playground.com/scenes/skull.babylon",
          scaling: [0.02, 0.02, 0.02],
          position: [4, 0.5, 0]
        }
      }),
      _c("Asset", {
        attrs: {
          src:
            "https://www.babylonjs-playground.com/scenes/Buggy/glTF/buggy.gltf",
          scaling: [0.02, 0.02, 0.02],
          position: [1, 0, -1],
          rotation: [0, Math.PI / 2, 0]
        }
      }),
      _c("Asset", {
        attrs: {
          src: "https://www.babylonjs-playground.com/scenes/StanfordBunny.obj",
          scaling: [7.5, 7.5, 7.5],
          position: [-4, -0.25, -0.5],
          rotation: [0, Math.PI, 0]
        }
      }),
      _c("Asset", {
        attrs: {
          src: "https://rawgit.com/saswata26/misc/master/base.stl",
          scaling: [0.02, 0.02, 0.02],
          position: [-2, 0, -0.5]
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }