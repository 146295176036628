var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Scene",
    [
      _c("Camera"),
      _c("HemisphericLight", { attrs: { diffuse: "#0000FF" } }),
      _c(
        "Entity",
        { attrs: { position: [0, 0, 5] } },
        [
          _c(
            "Animation",
            { attrs: { property: "rotation.x", duration: 5 } },
            [
              _c("Key", { attrs: { frame: "0%", value: 0 } }),
              _c("Key", { attrs: { frame: "100%", value: Math.PI * 2 } })
            ],
            1
          ),
          _c("Animation", {
            attrs: { property: "rotation.y", duration: 5, end: Math.PI * 2 }
          }),
          _c("Animation", {
            attrs: { property: "rotation.z", duration: 5, end: Math.PI * 2 }
          }),
          _c("PointLight", { attrs: { diffuse: "#FF0000" } }),
          _vm._l([0, 4, -4], function(x) {
            return [
              _vm._l([0, 4, -4], function(y) {
                return _vm._l([0, 4, -4], function(z) {
                  return _c("Box", {
                    key: x + "," + y + "," + z,
                    attrs: { position: [x, y, z] }
                  })
                })
              })
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }