var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Scene",
    [
      _c("Camera", {
        attrs: { type: "arcRotate", beta: Math.PI / 4, radius: 20 }
      }),
      _c("HemisphericLight"),
      _c("Sphere", { attrs: { position: [-8, 0, 3], scaling: [2, 1, 5] } }),
      _c("Sphere", { attrs: { position: [-5, 0, 3], scaling: [2, 2, 3] } }),
      _c("Sphere", { attrs: { position: [-1, 0, 3], scaling: [4, 5, 1] } }),
      _c("Sphere", { attrs: { position: [6, 0, 3], scaling: [8, 5, 3] } }),
      _c("Box", { attrs: { position: [0, 0, -3], scaling: [1, 2, 3] } }),
      _c("Box", { attrs: { position: [-3, 0, -3], scaling: [2, 1, 2] } }),
      _c("Box", { attrs: { position: [-6, 0, -3], scaling: [1, 3, 4] } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }