var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("article", [
      _c("h2", [_vm._v("Types")]),
      _c("h4", [_vm._v("Description")]),
      _vm._v(" "),
      _c("h4", [_vm._v("Vector")]),
      _vm._v(" "),
      _c("p", [_vm._v("Vectors can be any of the following:")]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [_vm._v("An Array of 2, 3, or 4 Numbers between 0 and 1")]),
        _vm._v(" "),
        _c("li", [
          _vm._v("A call to the "),
          _c("code", [_vm._v("$vector")]),
          _vm._v(" helper using Numbers between 0 and 1")
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v("An Object from the "),
          _c("code", [_vm._v("Vector2")]),
          _vm._v(", "),
          _c("code", [_vm._v("Vector3")]),
          _vm._v(", or "),
          _c("code", [_vm._v("Vector4")]),
          _vm._v(" classes in BabylonJS")
        ])
      ]),
      _vm._v(" "),
      _c("h5", [_vm._v("Template:")]),
      _vm._v(" "),
      _c("pre", { staticClass: "language-html" }, [
        _c("code", { staticClass: "language-html" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
              _vm._v("Box")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "token attr-name" }, [
              _vm._v(":position")
            ]),
            _c("span", { staticClass: "token attr-value" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
              _vm._v("[1, 0, 1]"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
              _vm._v("Box")
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _vm._v("\n"),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
              _vm._v("Box")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "token attr-name" }, [
              _vm._v(":position")
            ]),
            _c("span", { staticClass: "token attr-value" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
              _vm._v("$vector(0, 1, 0)"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
              _vm._v("Box")
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _vm._v("\n"),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
              _vm._v("Sphere")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "token attr-name" }, [
              _vm._v(":position")
            ]),
            _c("span", { staticClass: "token attr-value" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
              _vm._v("myArray"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
              _vm._v("Sphere")
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _vm._v("\n"),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
              _vm._v("Cylinder")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "token attr-name" }, [
              _vm._v(":position")
            ]),
            _c("span", { staticClass: "token attr-value" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
              _vm._v("myVector"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
              _vm._v("Cylinder")
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _vm._v("\n")
        ])
      ]),
      _vm._v(" "),
      _c("h5", [_vm._v("Script:")]),
      _vm._v(" "),
      _c("pre", { staticClass: "language-js" }, [
        _c("code", { staticClass: "language-js" }, [
          _c("span", { staticClass: "token keyword" }, [_vm._v("let")]),
          _vm._v(" "),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
          _vm._v(" Vector3 "),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
          _vm._v(" "),
          _c("span", { staticClass: "token operator" }, [_vm._v("=")]),
          _vm._v(" "),
          _c("span", { staticClass: "token function" }, [_vm._v("require")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
          _c("span", { staticClass: "token string" }, [
            _vm._v("'vue-babylonjs/classes'")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
          _vm._v("\n\nmodule"),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
          _vm._v("exports "),
          _c("span", { staticClass: "token operator" }, [_vm._v("=")]),
          _vm._v(" "),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
          _vm._v("\n  "),
          _c("span", { staticClass: "token function" }, [_vm._v("data")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
          _vm._v(" "),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
          _vm._v("\n    "),
          _c("span", { staticClass: "token keyword" }, [_vm._v("return")]),
          _vm._v(" "),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
          _vm._v("\n      myArray"),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(":")]),
          _vm._v(" "),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("[")]),
          _c("span", { staticClass: "token number" }, [_vm._v("1")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
          _vm._v(" "),
          _c("span", { staticClass: "token number" }, [_vm._v("0")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
          _vm._v(" "),
          _c("span", { staticClass: "token number" }, [_vm._v("1")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("]")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
          _vm._v("\n      myVector"),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(":")]),
          _vm._v(" "),
          _c("span", { staticClass: "token keyword" }, [_vm._v("new")]),
          _vm._v(" "),
          _c("span", { staticClass: "token class-name" }, [_vm._v("Vector3")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
          _c("span", { staticClass: "token number" }, [_vm._v("0")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
          _vm._v(" "),
          _c("span", { staticClass: "token number" }, [_vm._v("1")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
          _vm._v(" "),
          _c("span", { staticClass: "token number" }, [_vm._v("0")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
          _vm._v("\n    "),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
          _vm._v("\n  "),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
          _vm._v("\n"),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
          _vm._v("\n")
        ])
      ]),
      _vm._v(" "),
      _c("h4", [_vm._v("Color")]),
      _vm._v(" "),
      _c("p", [_vm._v("Colors can be any of the following:")]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [_vm._v("An Array of 3 or 4 Numbers between 0 and 1")]),
        _vm._v(" "),
        _c("li", [
          _vm._v("A call to the "),
          _c("code", [_vm._v("$color")]),
          _vm._v(" helper using Numbers between 0 and 1")
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v("An Object from the "),
          _c("code", [_vm._v("Color3")]),
          _vm._v(" or "),
          _c("code", [_vm._v("Color4")]),
          _vm._v(" classes in BabylonJS")
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "A HTML color code String with an optional alpha channel (case insenisitive)"
          )
        ])
      ]),
      _vm._v(" "),
      _c("h5", [_vm._v("Template:")]),
      _vm._v(" "),
      _c("pre", { staticClass: "language-html" }, [
        _c("code", { staticClass: "language-html" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
              _vm._v("Material")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "token attr-name" }, [_vm._v(":color")]),
            _c("span", { staticClass: "token attr-value" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
              _vm._v("[1, 0, 1]"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
              _vm._v("Material")
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _vm._v("\n"),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
              _vm._v("Material")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "token attr-name" }, [_vm._v(":color")]),
            _c("span", { staticClass: "token attr-value" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
              _vm._v("$color(0, 1, 0)"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
              _vm._v("Material")
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _vm._v("\n"),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
              _vm._v("Material")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "token attr-name" }, [_vm._v(":color")]),
            _c("span", { staticClass: "token attr-value" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
              _vm._v("myArray"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
              _vm._v("Material")
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _vm._v("\n"),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
              _vm._v("Material")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "token attr-name" }, [_vm._v(":color")]),
            _c("span", { staticClass: "token attr-value" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
              _vm._v("myColor"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
              _vm._v("Material")
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _vm._v("\n\n"),
          _c("span", { staticClass: "token comment" }, [
            _vm._v(
              "<!-- html color codes (note string type does not use binding syntax!) -->"
            )
          ]),
          _vm._v("\n"),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
              _vm._v("Material")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "token attr-name" }, [_vm._v("color")]),
            _c("span", { staticClass: "token attr-value" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
              _vm._v("#f1f"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
              _vm._v("Material")
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _vm._v("\n"),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
              _vm._v("Material")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "token attr-name" }, [_vm._v("color")]),
            _c("span", { staticClass: "token attr-value" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
              _vm._v("#f1f1f1"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
              _vm._v("Material")
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _vm._v("\n\n"),
          _c("span", { staticClass: "token comment" }, [
            _vm._v("<!-- color codes with alpha channel (Color4) -->")
          ]),
          _vm._v("\n"),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
              _vm._v("Material")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "token attr-name" }, [_vm._v("color")]),
            _c("span", { staticClass: "token attr-value" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
              _vm._v("#f1f1"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
              _vm._v("Material")
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _vm._v("\n"),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
              _vm._v("Material")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "token attr-name" }, [_vm._v("color")]),
            _c("span", { staticClass: "token attr-value" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
              _vm._v("#f1f1f1f1"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
              _vm._v("Material")
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _vm._v("\n")
        ])
      ]),
      _vm._v(" "),
      _c("h5", [_vm._v("Script:")]),
      _vm._v(" "),
      _c("pre", { staticClass: "language-js" }, [
        _c("code", { staticClass: "language-js" }, [
          _c("span", { staticClass: "token keyword" }, [_vm._v("let")]),
          _vm._v(" "),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
          _vm._v(" Color3 "),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
          _vm._v(" "),
          _c("span", { staticClass: "token operator" }, [_vm._v("=")]),
          _vm._v(" "),
          _c("span", { staticClass: "token function" }, [_vm._v("require")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
          _c("span", { staticClass: "token string" }, [
            _vm._v("'vue-babylonjs/classes'")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
          _vm._v("\n\nmodule"),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
          _vm._v("exports "),
          _c("span", { staticClass: "token operator" }, [_vm._v("=")]),
          _vm._v(" "),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
          _vm._v("\n  "),
          _c("span", { staticClass: "token function" }, [_vm._v("data")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
          _vm._v(" "),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
          _vm._v("\n    "),
          _c("span", { staticClass: "token keyword" }, [_vm._v("return")]),
          _vm._v(" "),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
          _vm._v("\n      myArray"),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(":")]),
          _vm._v(" "),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("[")]),
          _c("span", { staticClass: "token number" }, [_vm._v("1")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
          _vm._v(" "),
          _c("span", { staticClass: "token number" }, [_vm._v("0")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
          _vm._v(" "),
          _c("span", { staticClass: "token number" }, [_vm._v("1")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("]")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
          _vm._v("\n      myColor3"),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(":")]),
          _vm._v(" "),
          _c("span", { staticClass: "token keyword" }, [_vm._v("new")]),
          _vm._v(" "),
          _c("span", { staticClass: "token class-name" }, [_vm._v("Color3")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
          _c("span", { staticClass: "token number" }, [_vm._v("0")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
          _vm._v(" "),
          _c("span", { staticClass: "token number" }, [_vm._v("1")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
          _vm._v(" "),
          _c("span", { staticClass: "token number" }, [_vm._v("0")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
          _vm._v("\n    "),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
          _vm._v("\n  "),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
          _vm._v("\n"),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
          _vm._v("\n")
        ])
      ]),
      _vm._v(" "),
      _c("h4", [_vm._v("Matrix")]),
      _vm._v(" "),
      _c("p", [_vm._v("Matricies can be any of the following:")]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [_vm._v("An Array of Numbers")]),
        _vm._v(" "),
        _c("li", [
          _vm._v("A call to the "),
          _c("code", [_vm._v("$matrix")]),
          _vm._v(" helper with Numbers as arguments")
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v("An Object from the "),
          _c("code", [_vm._v("Matrix")]),
          _vm._v(" class in BabylonJS")
        ])
      ]),
      _vm._v(" "),
      _c("h5", [_vm._v("Template:")]),
      _vm._v(" "),
      _c("pre", { staticClass: "language-html" }, [
        _c("code", { staticClass: "language-html" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
              _vm._v("Uniform")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "token attr-name" }, [
              _vm._v(":matrix3x3")
            ]),
            _c("span", { staticClass: "token attr-value" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
              _vm._v("[1, 2, 3, 4, 5, 6, 7, 8, 9]"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
              _vm._v("Uniform")
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _vm._v("\n"),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
              _vm._v("Uniform")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "token attr-name" }, [
              _vm._v(":matrix4x4")
            ]),
            _c("span", { staticClass: "token attr-value" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
              _vm._v("$matrix(1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6)"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
              _vm._v("Uniform")
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _vm._v("\n"),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
              _vm._v("Uniform")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "token attr-name" }, [
              _vm._v(":matrix3x3")
            ]),
            _c("span", { staticClass: "token attr-value" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
              _vm._v("myArray"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
              _vm._v("Uniform")
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _vm._v("\n"),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
              _vm._v("Uniform")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "token attr-name" }, [
              _vm._v(":matrix3x3")
            ]),
            _c("span", { staticClass: "token attr-value" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
              _vm._v("myMatrix"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
              _vm._v("Uniform")
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _vm._v("\n")
        ])
      ]),
      _vm._v(" "),
      _c("h5", [_vm._v("Script:")]),
      _vm._v(" "),
      _c("pre", { staticClass: "language-js" }, [
        _c("code", { staticClass: "language-js" }, [
          _c("span", { staticClass: "token keyword" }, [_vm._v("let")]),
          _vm._v(" "),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
          _vm._v(" Matrix "),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
          _vm._v(" "),
          _c("span", { staticClass: "token operator" }, [_vm._v("=")]),
          _vm._v(" "),
          _c("span", { staticClass: "token function" }, [_vm._v("require")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
          _c("span", { staticClass: "token string" }, [
            _vm._v("'vue-babylonjs/classes'")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
          _vm._v("\n\nmodule"),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
          _vm._v("exports "),
          _c("span", { staticClass: "token operator" }, [_vm._v("=")]),
          _vm._v(" "),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
          _vm._v("\n  "),
          _c("span", { staticClass: "token function" }, [_vm._v("data")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
          _vm._v(" "),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
          _vm._v("\n    "),
          _c("span", { staticClass: "token keyword" }, [_vm._v("return")]),
          _vm._v(" "),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
          _vm._v("\n      myArray"),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(":")]),
          _vm._v(" "),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("[")]),
          _c("span", { staticClass: "token number" }, [_vm._v("1")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
          _vm._v(" "),
          _c("span", { staticClass: "token number" }, [_vm._v("2")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
          _vm._v(" "),
          _c("span", { staticClass: "token number" }, [_vm._v("3")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
          _vm._v(" "),
          _c("span", { staticClass: "token number" }, [_vm._v("4")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
          _vm._v(" "),
          _c("span", { staticClass: "token number" }, [_vm._v("5")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
          _vm._v(" "),
          _c("span", { staticClass: "token number" }, [_vm._v("6")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
          _vm._v(" "),
          _c("span", { staticClass: "token number" }, [_vm._v("7")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
          _vm._v(" "),
          _c("span", { staticClass: "token number" }, [_vm._v("8")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
          _vm._v(" "),
          _c("span", { staticClass: "token number" }, [_vm._v("9")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("]")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
          _vm._v("\n      myMatrix"),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(":")]),
          _vm._v(" "),
          _c("span", { staticClass: "token keyword" }, [_vm._v("new")]),
          _vm._v(" "),
          _c("span", { staticClass: "token class-name" }, [_vm._v("Matrix")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
          _c("span", { staticClass: "token number" }, [_vm._v("0")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
          _vm._v(" "),
          _c("span", { staticClass: "token number" }, [_vm._v("1")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
          _vm._v(" "),
          _c("span", { staticClass: "token number" }, [_vm._v("0")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
          _vm._v("\n    "),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
          _vm._v("\n  "),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
          _vm._v("\n"),
          _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
          _vm._v("\n")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }