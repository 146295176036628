var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Scene",
        {
          on: {
            complete: _vm.complete,
            "before-render$": _vm.beforeRender,
            "after-render$": function($event) {
              ++_vm.frames
            }
          }
        },
        [
          _c("Box", {
            attrs: { position: [-2, 0, 5], scaling: _vm.scale.box },
            model: {
              value: _vm.box,
              callback: function($$v) {
                _vm.box = $$v
              },
              expression: "box"
            }
          }),
          _c("Sphere", {
            attrs: { position: [2, 0, 5], scaling: _vm.scale.sphere },
            on: { entity: _vm.onSphere }
          })
        ],
        1
      ),
      _c("div", {
        staticStyle: {
          position: "absolute",
          color: "white",
          bottom: "0",
          padding: "15px"
        },
        domProps: { textContent: _vm._s("Frames: " + _vm.frames) }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }