var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("article", [
    _c("h2", [_vm._v("Installation")]),
    _c("p"),
    _c("h5", [_vm._v("Notes:")]),
    _c("ul", [
      _c("li", [
        _vm._v(
          "Both an NPM package and CDN options are available for installation."
        )
      ]),
      _c(
        "li",
        [
          _vm._v(
            "It is recommended to use the NPM package with a build tool such as "
          ),
          _c("external", { attrs: { href: "https://webpack.js.org/" } }, [
            _vm._v("Webpack")
          ]),
          _vm._v(" to optimize your usage of the plugin.")
        ],
        1
      ),
      _c("li", [
        _vm._v(
          "All dependencies (Babylon.js, Earcut, Cannon, and Oimo) are included with the package (minus Vue)."
        )
      ]),
      _vm._m(0)
    ]),
    _c("p"),
    _c("h5", [_vm._v("From NPM")]),
    _vm._m(1),
    _vm._m(2),
    _vm._m(3),
    _c("p", [_vm._v("Use the library like this in your script:")]),
    _vm._m(4),
    _vm._m(5),
    _vm._m(6),
    _vm._m(7),
    _c(
      "p",
      [
        _c(
          "external",
          {
            attrs: { href: "https://glitch.com/edit/#!/vue-babylonjs-starter" }
          },
          [_vm._v("See the complete starter example on Glitch")]
        )
      ],
      1
    ),
    _c("h5", [_vm._v("Via CDN")]),
    _c(
      "p",
      [
        _vm._v("Get the latest version of Vue-BabylonJS from "),
        _c(
          "external",
          {
            attrs: { href: "https://www.jsdelivr.com", title: "jsDelivr cdn" }
          },
          [_vm._v("jsDelivr")]
        ),
        _vm._v(":")
      ],
      1
    ),
    _vm._m(8),
    _c("p"),
    _c("p", [
      _vm._v(
        "When the script has loaded you can use the library in a script like this:"
      )
    ]),
    _vm._m(9),
    _vm._m(10),
    _c("h2", [_vm._v("Usage")]),
    _c("h5", [_vm._v("Basics")]),
    _c("p", [
      _vm._v(
        "All installation methods can be initialized with the following Vue template"
      )
    ]),
    _vm._m(11),
    _vm._m(12),
    _vm._m(13),
    _c("h5", [_vm._v("Advanced ES Module support")]),
    _vm._m(14),
    _c("p", [
      _vm._v(
        "Example usage of cherry-picking components with the ES Module functionality:"
      )
    ]),
    _vm._m(15),
    _c("p", [
      _vm._v(
        "You can also use individual exports as mixins or in specific components:"
      )
    ]),
    _c("p", [_vm._v("Initialization in script:")]),
    _vm._m(16),
    _c("p", [_vm._v("Usage in component:")]),
    _vm._m(17)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _vm._v("The global variable exposed by the library is "),
      _c("code", [_vm._v("VueBaybylonjs")]),
      _vm._v(" when using the CDN or UMD package without a module system.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("pre", [
      _c("code", { attrs: { lang: "bash" } }, [
        _vm._v("$ "),
        _c("span", { staticClass: "token function" }, [_vm._v("npm")]),
        _vm._v(" "),
        _c("span", { staticClass: "token function" }, [_vm._v("install")]),
        _vm._v(" vue-babylonjs")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("or")]), _vm._v(" (using Yarn):")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("pre", [
      _c("code", { attrs: { lang: "bash" } }, [
        _vm._v("$ "),
        _c("span", { staticClass: "token function" }, [_vm._v("yarn")]),
        _vm._v(" "),
        _c("span", { staticClass: "token function" }, [_vm._v("add")]),
        _vm._v(" vue-babylonjs")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("pre", [
      _c("code", { attrs: { lang: "js" } }, [
        _c("span", { staticClass: "token keyword" }, [_vm._v("let")]),
        _vm._v(" Vue "),
        _c("span", { staticClass: "token operator" }, [_vm._v("=")]),
        _vm._v(" "),
        _c("span", { staticClass: "token function" }, [_vm._v("require")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
        _c("span", { staticClass: "token string" }, [_vm._v("'vue'")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
        _vm._v("\nVue"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
        _c("span", { staticClass: "token function" }, [_vm._v("use")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
        _c("span", { staticClass: "token function" }, [_vm._v("require")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
        _c("span", { staticClass: "token string" }, [
          _vm._v("'vue-babylonjs'")
        ]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(";")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("or")]),
      _vm._v(" (Using ES Modules):")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("pre", [
      _c("code", { attrs: { lang: "js" } }, [
        _c("span", { staticClass: "token keyword" }, [_vm._v("import")]),
        _vm._v(" Vue "),
        _c("span", { staticClass: "token keyword" }, [_vm._v("from")]),
        _vm._v(" "),
        _c("span", { staticClass: "token string" }, [_vm._v("'vue'")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
        _vm._v("\n"),
        _c("span", { staticClass: "token keyword" }, [_vm._v("import")]),
        _vm._v(" vb "),
        _c("span", { staticClass: "token keyword" }, [_vm._v("from")]),
        _vm._v(" "),
        _c("span", { staticClass: "token string" }, [
          _vm._v("'vue-babylonjs'")
        ]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
        _vm._v("\nVue"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
        _c("span", { staticClass: "token function" }, [_vm._v("use")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
        _vm._v("vb"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(";")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v(
          "The main file provided by this NPM package is a UMD module that will export properly for CommonJS, Web, or AMD environments."
        )
      ]),
      _c("li", [
        _vm._v(
          "A module is also provided for ES Module support for the tree-shaking use-case."
        )
      ]),
      _c("li", [
        _vm._v(
          "See more in the Usage section below for information on how to leverage tree-shaking with ES Modules."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("pre", [
      _c("code", [
        _c("code", { attrs: { lang: "markup" } }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
              _vm._v("script")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "token attr-name" }, [_vm._v("src")]),
            _c("span", { staticClass: "token attr-value" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
              _vm._v("https://cdn.jsdelivr.net/npm/vue-babylonjs@1.0.0-beta.7"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _c("span", { staticClass: "token script" }),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
              _vm._v("script")
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("pre", [
      _c("code", { attrs: { lang: "js" } }, [
        _vm._v("Vue"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
        _c("span", { staticClass: "token function" }, [_vm._v("use")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
        _vm._v("window"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
        _vm._v("VueBabylonjs"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(";")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v(
          "The CDN version includes all of the Components available in this library."
        )
      ]),
      _c("li", [
        _vm._v(
          "This includes a Physics component that only uses Cannon.js and omits Oimo.js."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("pre", [
      _c("code", { attrs: { lang: "markup" } }, [
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Scene")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n  "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Box")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v(":position")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("[0, 0, 5]"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Box")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n"),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Scene")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("or")]), _vm._v(" (using Pug):")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("pre", [
      _c("code", { attrs: { lang: "pug" } }, [
        _c("span", { staticClass: "token tag" }, [_vm._v("Scene")]),
        _vm._v("\n  "),
        _c("span", { staticClass: "token tag" }, [
          _vm._v("Box"),
          _c("span", { staticClass: "token attributes" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
            _vm._v(":"),
            _c("span", { staticClass: "token attr-name" }, [
              _vm._v("position")
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token attr-value" }, [
              _vm._v('"'),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("[")]),
              _c("span", { staticClass: "token number" }, [_vm._v("0")])
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
            _vm._v(" "),
            _c("span", { staticClass: "token attr-name" }, [_vm._v("0")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
            _vm._v(' 5]"'),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(")")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v(
          "You can import specific portions of the library to leverage tree-shaking in compatible build tools, e.g. Webpack."
        )
      ]),
      _c("li", [
        _vm._v(
          "All components, including the mesh components, are exported individually from the ES Module."
        )
      ]),
      _c("li", [
        _vm._v("A special "),
        _c("code", [_vm._v("plugin")]),
        _vm._v(
          " export contains the installation logic for this plugin without any components."
        )
      ]),
      _c("li", [
        _vm._v("The export "),
        _c("code", [_vm._v("BABYLON")]),
        _vm._v(" contains all of the classes exported by Babylon.js.")
      ]),
      _c("li", [
        _vm._v("The "),
        _c("code", [_vm._v("default")]),
        _vm._v(
          " export contains installation logic that will include all components, effectively bypassing any tree-shaking functionality."
        )
      ]),
      _c("li", [
        _vm._v("The "),
        _c("code", [_vm._v("components")]),
        _vm._v(
          " option of the installation mechanism also allows for renaming components."
        )
      ]),
      _c("li", [
        _vm._v(
          "Physics components are individually exported per their underlying library as "
        ),
        _c("code", [_vm._v("Cannon")]),
        _vm._v(" and "),
        _c("code", [_vm._v("Oimo")]),
        _vm._v(".")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("pre", [
      _c("code", { attrs: { lang: "js" } }, [
        _c("span", { staticClass: "token keyword" }, [_vm._v("import")]),
        _vm._v(" Vue "),
        _c("span", { staticClass: "token keyword" }, [_vm._v("from")]),
        _vm._v(" "),
        _c("span", { staticClass: "token string" }, [_vm._v("'vue'")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
        _vm._v("\n"),
        _c("span", { staticClass: "token keyword" }, [_vm._v("import")]),
        _vm._v(" "),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
        _vm._v(" plugin"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
        _vm._v(" Scene"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
        _vm._v(" Box"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
        _vm._v(" Cannon "),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
        _vm._v(" "),
        _c("span", { staticClass: "token keyword" }, [_vm._v("from")]),
        _vm._v(" "),
        _c("span", { staticClass: "token string" }, [
          _vm._v("'vue-babylonjs'")
        ]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
        _vm._v("\nVue"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
        _c("span", { staticClass: "token function" }, [_vm._v("use")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
        _vm._v("plugin"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
        _vm._v(" "),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
        _vm._v(" components"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(":")]),
        _vm._v(" "),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
        _vm._v(" Scene"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
        _vm._v(" Box"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
        _vm._v(" Physics"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(":")]),
        _vm._v(" Cannon "),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
        _vm._v(" "),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(";")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("pre", [
      _c("code", { attrs: { lang: "js" } }, [
        _c("span", { staticClass: "token keyword" }, [_vm._v("import")]),
        _vm._v(" "),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
        _vm._v(" plugin "),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
        _vm._v(" "),
        _c("span", { staticClass: "token keyword" }, [_vm._v("from")]),
        _vm._v(" "),
        _c("span", { staticClass: "token string" }, [
          _vm._v("'vue-babylonjs'")
        ]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
        _vm._v("\nVue"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
        _c("span", { staticClass: "token function" }, [_vm._v("use")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
        _vm._v("plugin"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(";")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("pre", [
      _c("code", { attrs: { lang: "js" } }, [
        _c("span", { staticClass: "token keyword" }, [_vm._v("import")]),
        _vm._v(" "),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
        _vm._v(" Entity"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
        _vm._v(" Scene"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
        _vm._v(" Box "),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
        _vm._v(" "),
        _c("span", { staticClass: "token keyword" }, [_vm._v("from")]),
        _vm._v(" "),
        _c("span", { staticClass: "token string" }, [
          _vm._v("'vue-babylonjs'")
        ]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
        _vm._v("\n"),
        _c("span", { staticClass: "token keyword" }, [_vm._v("export")]),
        _vm._v(" "),
        _c("span", { staticClass: "token keyword" }, [_vm._v("default")]),
        _vm._v(" "),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
        _vm._v("\n  mixins"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(":")]),
        _vm._v(" "),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("[")]),
        _vm._v("Entity"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("]")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
        _vm._v("\n  components"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(":")]),
        _vm._v(" "),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
        _vm._v(" Scene"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
        _vm._v(" Box "),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
        _vm._v("\n"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(";")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }