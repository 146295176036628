var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Scene",
    [
      _c("Camera", { attrs: { type: "arcRotate" } }),
      _c("PointLight", { attrs: { position: [0, 2, -1] } }),
      _c(
        "Sphere",
        { attrs: { position: [-2, 0, 0] } },
        [_c("Material", { attrs: { diffuse: "#00F" } })],
        1
      ),
      _c(
        "Cylinder",
        { attrs: { position: [0, 0.5, 0] } },
        [_c("Material", { attrs: { emissive: "#008800" } })],
        1
      ),
      _c(
        "Box",
        { attrs: { position: [2, 0, 0] } },
        [
          _c("Material", {
            attrs: { diffuse: "#F00", metallic: 0, roughness: 1 }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }