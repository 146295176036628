var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Scene",
    [
      _c("Camera", {
        attrs: { type: "arcRotate", radius: 10, beta: Math.PI / 4 }
      }),
      _c(
        "HemisphericLight",
        [_c("property", { attrs: { name: "intensity", float: 2 } })],
        1
      ),
      _c(
        "Ground",
        { attrs: { options: { width: 10, height: 10 } } },
        [_c("Physics")],
        1
      ),
      _vm._l(Array(81).fill(), function(_, i) {
        return _c(
          "Entity",
          {
            key: i,
            attrs: {
              position: [
                (i % 3) - 1,
                2 + Math.floor(i / 9),
                (Math.floor(i / 3) % 3) - 1
              ],
              scaling: [1, 1, 0.3]
            }
          },
          [
            _c("Physics", { attrs: { mass: 1 } }),
            _c(
              "Entity",
              { attrs: { scaling: [1, 1, 1 / 0.3] } },
              [
                _c("LogoSide"),
                _c(
                  "Entity",
                  { attrs: { scaling: [-1, 1, 1] } },
                  [_c("LogoSide")],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }