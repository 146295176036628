var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    [
      _c("h2", [_vm._v("Shader")]),
      _c("h4", [_vm._v("Example")]),
      _c(
        "Controls",
        { attrs: { inline: true, route: "/shader/fullscreen" } },
        [
          _c("Example"),
          _c("h5", { attrs: { slot: "filename" }, slot: "filename" }, [
            _vm._v("shader.vue")
          ]),
          _c("pre", { attrs: { slot: "pug" }, slot: "pug" }, [
            _c("code", { attrs: { lang: "pug" } }, [
              _c("span", { staticClass: "token tag" }, [_vm._v("Scene")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Camera"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("type")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"arcRotate"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Box"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _vm._v(":"),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("position")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token number" }, [_vm._v("2")])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "token attr-name" }, [_vm._v("0")]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(' 0]"'),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Animation"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("property")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"rotation.x"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("duration"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"5"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("end"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"Math.PI * 2"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v(
                  "// Use a vertex and fragment registered with ShadersStore under the same name"
                )
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("// Safe caching, non-reactive")
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("// Recommended for reusable shader files")
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Shader"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _vm._v(":"),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("shader")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"shader"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("// How to set uniform values")
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Uniform"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("variable")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"color"')
                    ]),
                    _vm._v(" color"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"#F00"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Uniform"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("variable")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"start"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("float"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"0.5"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n\n  "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Box"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _vm._v(":"),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("position")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token number" }, [_vm._v("0")])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("-2")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(' 0]"'),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Animation"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("property")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"rotation.y"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("duration"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"5"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("end"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"Math.PI * 2"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v(
                  "// Use shaders registered with ShadersStore under specific names"
                )
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("// Safe caching, non-reactive")
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("// Recommended for independently reusable shader files")
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Shader"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _vm._v(":"),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("vertex")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"shader"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("fragment"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"shader"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Uniform"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("variable")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"color"')
                    ]),
                    _vm._v(" color"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"#0F0"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Uniform"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("variable")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"start"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("float"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [_vm._v('"1"')])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n\n  "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Box"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _vm._v(":"),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("position")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("-")
                    ]),
                    _c("span", { staticClass: "token number" }, [_vm._v("2")])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "token attr-name" }, [_vm._v("0")]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(' 0]"'),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Animation"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("property")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"rotation.x"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("duration"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"5"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("end"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"-Math.PI * 2"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v(
                  "// Use the raw text content from strings defined in this component"
                )
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("// Unsafe caching, reactive")
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v(
                  '// USE A UNIQUE VALUE IN THE "name" PROPERTY FOR SAFE CACHING'
                )
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("// Recommended for reusable shader components")
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Shader"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("name")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"inline"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("vertexShader"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"vertexShader"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("fragmentShader"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"fragmentShader"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Uniform"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("variable")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"color"')
                    ]),
                    _vm._v(" color"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"#00F"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Uniform"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("variable")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"start"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("float"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"-1"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n\n  "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Box"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _vm._v(":"),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("position")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token number" }, [_vm._v("0")])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "token attr-name" }, [_vm._v("2")]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(' 0]"'),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Animation"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("property")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"rotation.y"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("duration"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"5"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("end"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"-Math.PI * 2"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v(
                  "// Use the raw text content from strings defined in this component"
                )
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("// Unsafe caching, reactive")
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v(
                  '// USE A UNIQUE VALUE IN THE "name" PROPERTY FOR SAFE CACHING'
                )
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v(
                  "// Recommended for independently reusable shader components"
                )
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [_vm._v("Shader")]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Vertex"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("name")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"namedvertex"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("content"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"vertexShader"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Fragment"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("name")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"namedfragment"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("content"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"fragmentShader"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Uniform"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("variable")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"color"')
                    ]),
                    _vm._v(" color"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"#FF0"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Uniform"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("variable")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"start"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("float"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"-0.5"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n\n  "),
              _c("span", { staticClass: "token tag" }, [_vm._v("Box")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Animation"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("property")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"rotation.x"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("duration"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"5"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("end"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"Math.PI * 2"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Animation"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("property")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"rotation.y"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("duration"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"5"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("end"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"Math.PI * 2"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Animation"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("property")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"rotation.z"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("duration"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"5"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("end"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"Math.PI * 2"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v(
                  "// Use the text content inside Vertex and Fragment components"
                )
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("// Unsafe caching, non-reactive")
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v(
                  '// USE A UNIQUE VALUE IN THE "name" PROPERTY FOR SAFE CACHING'
                )
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("// only recommended for prototyping")
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Shader"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("name")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"text"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n\n      "),
              _c("span", { staticClass: "token tag" }, [_vm._v("Vertex")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _c("span", { staticClass: "token multiline-plain-text" }, [
                _vm._v(
                  "\n        precision highp float;\n        attribute vec3 position;\n        uniform mat4 worldViewProjection;\n        uniform float time;\n        varying vec3 direction;\n\n        void main() {\n          gl_Position = worldViewProjection * vec4(position, 1.0);\n          direction = position;\n        }\n"
                )
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [_vm._v("Fragment")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _c("span", { staticClass: "token multiline-plain-text" }, [
                _vm._v(
                  "\n        precision highp float;\n        uniform float time;\n        varying vec3 direction;\n\n        void main() {\n          gl_FragColor = vec4(cos(time / 1000.0) * direction, 1.0);\n        }\n"
                )
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("// Others:")
              ]),
              _vm._v("\n\n  "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v(
                  '//  Box\n      // Use an element in DOM with a pre-defined id (not recommended for Vue)\n      Shader(name="elements" vertexElement="someid" fragmentElement="someotherid")'
                )
              ]),
              _vm._v("\n\n  "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v(
                  '//  Box\n      // Use an fx file on your server\n      Shader(name="fxfile" src="/path/to/file.fx")'
                )
              ]),
              _vm._v("\n")
            ])
          ]),
          _c("pre", { attrs: { slot: "html" }, slot: "html" }, [
            _c("code", { attrs: { lang: "markup" } }, [
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Scene")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Camera")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("type")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("arcRotate"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Camera")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Box")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":position")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("[2, 0, 0]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Animation")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("property")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("rotation.x"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":duration")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("5"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":end")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("Math.PI * 2"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Animation")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v(
                  "<!-- Use a vertex and fragment registered with ShadersStore under the same name-->"
                )
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("<!-- Safe caching, non-reactive-->")
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("<!-- Recommended for reusable shader files-->")
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Shader")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":shader")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("shader"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("<!-- How to set uniform values-->")
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Uniform")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("variable")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("color"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("color")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("#F00"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Uniform")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Uniform")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("variable")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("start"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":float")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("0.5"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Uniform")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Shader")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Box")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Box")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":position")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("[0, -2, 0]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Animation")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("property")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("rotation.y"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":duration")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("5"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":end")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("Math.PI * 2"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Animation")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v(
                  "<!-- Use shaders registered with ShadersStore under specific names-->"
                )
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("<!-- Safe caching, non-reactive-->")
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v(
                  "<!-- Recommended for independently reusable shader files-->"
                )
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Shader")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":vertex")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("shader"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":fragment")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("shader"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Uniform")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("variable")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("color"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("color")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("#0F0"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Uniform")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Uniform")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("variable")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("start"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":float")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("1"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Uniform")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Shader")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Box")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Box")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":position")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("[-2, 0, 0]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Animation")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("property")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("rotation.x"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":duration")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("5"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":end")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("-Math.PI * 2"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Animation")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v(
                  "<!-- Use the raw text content from strings defined in this component-->"
                )
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("<!-- Unsafe caching, reactive-->")
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v(
                  '<!-- USE A UNIQUE VALUE IN THE "name" PROPERTY FOR SAFE CACHING-->'
                )
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("<!-- Recommended for reusable shader components-->")
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Shader")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("name")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("inline"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":vertexShader")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("vertexShader"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":fragmentShader")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("fragmentShader"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Uniform")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("variable")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("color"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("color")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("#00F"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Uniform")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Uniform")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("variable")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("start"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":float")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("-1"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Uniform")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Shader")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Box")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Box")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":position")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("[0, 2, 0]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Animation")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("property")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("rotation.y"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":duration")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("5"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":end")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("-Math.PI * 2"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Animation")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v(
                  "<!-- Use the raw text content from strings defined in this component-->"
                )
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("<!-- Unsafe caching, reactive-->")
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v(
                  '<!-- USE A UNIQUE VALUE IN THE "name" PROPERTY FOR SAFE CACHING-->'
                )
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v(
                  "<!-- Recommended for independently reusable shader components-->"
                )
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Shader")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Vertex")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("name")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("namedvertex"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":content")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("vertexShader"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Vertex")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Fragment")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("name")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("namedfragment"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":content")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("fragmentShader"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Fragment")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Uniform")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("variable")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("color"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("color")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("#FF0"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Uniform")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Uniform")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("variable")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("start"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":float")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("-0.5"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Uniform")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Shader")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Box")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Box")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Animation")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("property")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("rotation.x"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":duration")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("5"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":end")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("Math.PI * 2"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Animation")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Animation")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("property")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("rotation.y"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":duration")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("5"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":end")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("Math.PI * 2"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Animation")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Animation")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("property")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("rotation.z"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":duration")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("5"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":end")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("Math.PI * 2"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Animation")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v(
                  "<!-- Use the text content inside Vertex and Fragment components-->"
                )
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("<!-- Unsafe caching, non-reactive-->")
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v(
                  '<!-- USE A UNIQUE VALUE IN THE "name" PROPERTY FOR SAFE CACHING-->'
                )
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("<!-- only recommended for prototyping-->")
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Shader")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("name")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("text"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Vertex")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v(
                "\n        precision highp float;\n        attribute vec3 position;\n        uniform mat4 worldViewProjection;\n        uniform float time;\n        varying vec3 direction;\n        \n        void main() {\n          gl_Position = worldViewProjection * vec4(position, 1.0);\n          direction = position;\n        }\n        \n      "
              ),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Vertex")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Fragment")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v(
                "\n        precision highp float;\n        uniform float time;\n        varying vec3 direction;\n        \n        void main() {\n          gl_FragColor = vec4(cos(time / 1000.0) * direction, 1.0);\n        }\n        \n      "
              ),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Fragment")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Shader")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Box")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v("<!-- Others:-->")
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v(
                  '<!--  Box\n  // Use an element in DOM with a pre-defined id (not recommended for Vue)\n  Shader(name="elements" vertexElement="someid" fragmentElement="someotherid")\n  \n  -->'
                )
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token comment" }, [
                _vm._v(
                  '<!--  Box\n  // Use an fx file on your server\n  Shader(name="fxfile" src="/path/to/file.fx")\n  -->'
                )
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Scene")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ])
            ])
          ]),
          _c("pre", { attrs: { slot: "script" }, slot: "script" }, [
            _c("code", { attrs: { lang: "javascript" } }, [
              _c("span", { staticClass: "token keyword" }, [_vm._v("import")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v(" "),
              _c("span", { staticClass: "token constant" }, [
                _vm._v("BABYLON")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _vm._v(" "),
              _c("span", { staticClass: "token keyword" }, [_vm._v("from")]),
              _vm._v(" "),
              _c("span", { staticClass: "token string" }, [
                _vm._v("'vue-babylonjs'")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
              _vm._v("\n\n"),
              _c("span", { staticClass: "token keyword" }, [_vm._v("import")]),
              _vm._v(" "),
              _c("span", { staticClass: "token constant" }, [_vm._v("VERTEX")]),
              _vm._v(" "),
              _c("span", { staticClass: "token keyword" }, [_vm._v("from")]),
              _vm._v(" "),
              _c("span", { staticClass: "token string" }, [
                _vm._v("'./vertex.glsl'")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
              _vm._v("\n"),
              _c("span", { staticClass: "token keyword" }, [_vm._v("import")]),
              _vm._v(" "),
              _c("span", { staticClass: "token constant" }, [
                _vm._v("FRAGMENT")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "token keyword" }, [_vm._v("from")]),
              _vm._v(" "),
              _c("span", { staticClass: "token string" }, [
                _vm._v("'./fragment.glsl'")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
              _vm._v("\n\n"),
              _c("span", { staticClass: "token keyword" }, [_vm._v("const")]),
              _vm._v(" "),
              _c("span", { staticClass: "token constant" }, [_vm._v("NAME")]),
              _vm._v(" "),
              _c("span", { staticClass: "token operator" }, [_vm._v("=")]),
              _vm._v(" "),
              _c("span", { staticClass: "token string" }, [_vm._v("'demo'")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
              _vm._v("\n\n"),
              _c("span", { staticClass: "token constant" }, [
                _vm._v("BABYLON")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _vm._v("Effect"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _vm._v("ShadersStore"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("[")]),
              _c("span", { staticClass: "token template-string" }, [
                _c("span", { staticClass: "token string" }, [_vm._v("`")]),
                _c("span", { staticClass: "token interpolation" }, [
                  _c(
                    "span",
                    {
                      staticClass: "token interpolation-punctuation punctuation"
                    },
                    [_vm._v("${")]
                  ),
                  _c("span", { staticClass: "token constant" }, [
                    _vm._v("NAME")
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "token interpolation-punctuation punctuation"
                    },
                    [_vm._v("}")]
                  )
                ]),
                _c("span", { staticClass: "token string" }, [
                  _vm._v("VertexShader`")
                ])
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("]")]),
              _vm._v(" "),
              _c("span", { staticClass: "token operator" }, [_vm._v("=")]),
              _vm._v(" "),
              _c("span", { staticClass: "token constant" }, [_vm._v("VERTEX")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
              _vm._v("\n"),
              _c("span", { staticClass: "token constant" }, [
                _vm._v("BABYLON")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _vm._v("Effect"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(".")]),
              _vm._v("ShadersStore"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("[")]),
              _c("span", { staticClass: "token template-string" }, [
                _c("span", { staticClass: "token string" }, [_vm._v("`")]),
                _c("span", { staticClass: "token interpolation" }, [
                  _c(
                    "span",
                    {
                      staticClass: "token interpolation-punctuation punctuation"
                    },
                    [_vm._v("${")]
                  ),
                  _c("span", { staticClass: "token constant" }, [
                    _vm._v("NAME")
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "token interpolation-punctuation punctuation"
                    },
                    [_vm._v("}")]
                  )
                ]),
                _c("span", { staticClass: "token string" }, [
                  _vm._v("FragmentShader`")
                ])
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("]")]),
              _vm._v(" "),
              _c("span", { staticClass: "token operator" }, [_vm._v("=")]),
              _vm._v(" "),
              _c("span", { staticClass: "token constant" }, [
                _vm._v("FRAGMENT")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
              _vm._v("\n\n"),
              _c("span", { staticClass: "token keyword" }, [_vm._v("export")]),
              _vm._v(" "),
              _c("span", { staticClass: "token keyword" }, [_vm._v("default")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n  computed"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token function" }, [
                _vm._v("vertexShader")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token keyword" }, [_vm._v("return")]),
              _vm._v(" "),
              _c("span", { staticClass: "token constant" }, [_vm._v("VERTEX")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n\n    "),
              _c("span", { staticClass: "token function" }, [
                _vm._v("fragmentShader")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token keyword" }, [_vm._v("return")]),
              _vm._v(" "),
              _c("span", { staticClass: "token constant" }, [
                _vm._v("FRAGMENT")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n\n    "),
              _c("span", { staticClass: "token function" }, [_vm._v("shader")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("(")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(")")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token keyword" }, [_vm._v("return")]),
              _vm._v(" "),
              _c("span", { staticClass: "token constant" }, [_vm._v("NAME")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
              _vm._v("\n")
            ])
          ])
        ],
        1
      ),
      _c("h4", [_vm._v("Details")]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("h4", [_vm._v("Usage")]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("h4", [_vm._v("Props")]),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c("h3", [_vm._v("Shader Content")]),
      _vm._v(" "),
      _c("p", [_vm._v("Use a separate component to define your shader code")]),
      _vm._v(" "),
      _c("h4", [_vm._v("Components")]),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _c("h4", [_vm._v("Details")]),
      _vm._v(" "),
      _vm._m(4),
      _vm._v(" "),
      _c("h4", [_vm._v("Usage")]),
      _vm._v(" "),
      _vm._m(5),
      _vm._v(" "),
      _c("h4", [_vm._v("Props")]),
      _vm._v(" "),
      _vm._m(6),
      _vm._v(" "),
      _c("h4", [_vm._v("Shader Variables")]),
      _vm._v(" "),
      _c("p", [_vm._v("Set a vairable for your shader code")]),
      _vm._v(" "),
      _c("h4", [_vm._v("Components")]),
      _vm._v(" "),
      _vm._m(7),
      _vm._v(" "),
      _c("h4", [_vm._v("Details")]),
      _vm._v(" "),
      _vm._m(8),
      _vm._v(" "),
      _c("h4", [_vm._v("Usage")]),
      _vm._v(" "),
      _vm._m(9),
      _vm._v(" "),
      _c("h4", [_vm._v("Props")]),
      _vm._v(" "),
      _vm._m(10)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [_vm._v("This must be a child of a Mesh component")]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "It is important to use a unique name value when using this component to leverage shader caching inside of the BabylonJS "
        ),
        _c("code", [_vm._v("ShadersStore")])
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v("Not using the "),
        _c("code", [_vm._v("name")]),
        _vm._v(" attribute when recommended may lead to memory leaks!")
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "There are several ways to define your vertex and fragment code try to follow the recommendations for each use-case"
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v("Pick one method of each loading vertex and fragment code")
      ]),
      _vm._v(" "),
      _c("li", [_vm._v("You can mix-and-match methods of loading")]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Create reusable components with Vue to share your shader materials with several different meshes"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("pre", { staticClass: "language-html" }, [
      _c("code", { staticClass: "language-html" }, [
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Scene")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n  "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Box")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n    "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Shader")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v("name")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("some_unique_value"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v("v-model")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("myMaterial"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Shader")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n  "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Box")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n"),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Scene")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("code", [_vm._v("name")]),
        _vm._v(" (String) - A unique id value to assign to this shader")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("vertex")]),
        _vm._v(
          " (String) - vertex name in shader store (recommended for independently resuable shader files) using this and "
        ),
        _c("code", [_vm._v("fragment")]),
        _vm._v(" together  this does not require a "),
        _c("code", [_vm._v("name")]),
        _vm._v(" attribute")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("vertexElement")]),
        _vm._v(
          " (String) - vertex script id (Not recommended, use the Vertex component)"
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("vertexShader")]),
        _vm._v(" (String) - raw vertex shader code (USE THE "),
        _c("code", [_vm._v("name")]),
        _vm._v(" prop for safe caching!)")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("fragment")]),
        _vm._v(
          " (String) - fragment name in shader store (recommended for independently resuable shader files) using this and "
        ),
        _c("code", [_vm._v("vertex")]),
        _vm._v(" together does not require a "),
        _c("code", [_vm._v("name")]),
        _vm._v(" attribute")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("fragmentElement")]),
        _vm._v(
          " (String) - fragment script id (Not recommended, use the Fragment component)"
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("fragmentShader")]),
        _vm._v(" (String) - raw fragment shader code (USE THE "),
        _c("code", [_vm._v("name")]),
        _vm._v(" prop for safe caching!)")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("shader")]),
        _vm._v(
          " (String) - shader and fragment name in shader store (recommended for resuable shader files) using this does not require a "
        ),
        _c("code", [_vm._v("name")]),
        _vm._v(" attribute")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("src")]),
        _vm._v(
          " (String) - path to an fx file on your server (Only recommended when using the webpack file plugin, using a CDN, or in specific environments)"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [_vm._v("Vertex")]),
      _vm._v(" "),
      _c("li", [_vm._v("Fragment")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [_vm._v("Must be a child of a Shader component")]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "When using only separate Vertex and/or Fragment components it is not necessary to provide a name to the parent Shader, but is necessary to provide to this component"
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "If the parent has a name then it is not necessary to provide a name to this component, both Vertex and Fragment components will inherit the name"
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Names can be shared if the Fragment and Vertex components are children of the same Shader component, but in this case you should probably provide the name to the Shader component instead"
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "When writing in the Pug langage, it is possible to provide body text of these components by using the dot ("
        ),
        _c("code", [_vm._v(".")]),
        _vm._v(") operator as the parent element")
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Body content is not reactive at the moment since Vue does not watch these changes by default"
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "It is only recommended to use body content shader code in the prototyping phase"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("pre", { staticClass: "language-html" }, [
      _c("code", { staticClass: "language-html" }, [
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Scene")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n  "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Box")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n    "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Shader")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n      "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Vertex")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v("name")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("some_unique_value"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v(":content")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("someVertexCodeString"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Vertex")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n      "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Fragment")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v("name")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("some_possibly_different_unique_value"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n        // Body content fragment code here\n      "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Fragment")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n    "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Shader")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n  "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Box")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n"),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Scene")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("code", [_vm._v("name")]),
        _vm._v(" (String) - A unique id value to assign to this shader")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("content")]),
        _vm._v(
          " (String) - The text content of the code instead of the body content of this element"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [_vm._v("Uniform")]),
      _vm._v(" "),
      _c("li", [_vm._v("Attribute")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [_vm._v("Must be a child of a Shader component")]),
      _vm._v(" "),
      _c("li", [
        _vm._v("Specific variable value types are converted automatically")
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Use only one appropriate attribute to define the type of your variable"
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v("Always use the "),
        _c("code", [_vm._v("variable")]),
        _vm._v(
          " attribute to define what your variable is named in your shader code"
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Variables are reactive and will update the Shader automatically when changed"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("pre", { staticClass: "language-html" }, [
      _c("code", { staticClass: "language-html" }, [
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Scene")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n  "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Box")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n    "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Shader")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v("name")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("some_unique_value"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n      "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Uniform")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v("variable")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("myUniformName"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v(":float")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("1"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Uniform")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n      "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Attribute")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v("variable")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("myAttributeName"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v(":float")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("2"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Attribute")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n      "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Uniform")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v("variable")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("myColorName"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v("color")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("#F00"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Attribute")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n      "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Uniform")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v("variable")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("myThirdColorName"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v(":color")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("[1, 0, 1, 0.5]"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Attribute")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n      "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Uniform")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v("variable")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("myVectorName"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v(":vector")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("[1, 0, 1]"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Attribute")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n      "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Uniform")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v("variable")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("myMatrixName"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v(":matrix")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("myMatrix"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Attribute")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n      "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Uniform")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v("variable")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("my2DMatrixName"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [
            _vm._v(":matrix2x2")
          ]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("my2DMatrix"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Attribute")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n      "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Uniform")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v("variable")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("my3DMatrixName"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [
            _vm._v(":matrix3x3")
          ]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("my3DMatrix"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Attribute")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n      "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Uniform")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v("variable")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("myArrayName"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v(":array")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("[1, 2, 3]"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Attribute")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n    "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Shader")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n  "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Box")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n"),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Scene")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("code", [_vm._v("variable")]),
        _vm._v(" (String) - [required] the name of the variable in GLSL code")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("float")]),
        _vm._v(" (Number) - a Float value to pass into the shader")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("color")]),
        _vm._v(" (Color3|Color4) - a Color value to pass into the shader")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("vector")]),
        _vm._v(
          " (Vector2|Vector3|Vector4) - a Vector value to pass into the shader"
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("matrix")]),
        _vm._v(" (Matrix) - a Matrix value to pass into the shader")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("matrix2x2")]),
        _vm._v(" (Matrix) - a 2D Matrix value to pass into the shader")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("matrix3x3")]),
        _vm._v(" (Matrix) - a 3D Matrix value to pass into the shader")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("array")]),
        _vm._v(" (Array) - an Array value to pass into the shader")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }