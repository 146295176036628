var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    [
      _c("h2", [_vm._v("Scene")]),
      _c("h4", [_vm._v("Example")]),
      _c(
        "Controls",
        { attrs: { inline: true, route: "/scene/fullscreen" } },
        [
          _c("Example"),
          _c("h5", { attrs: { slot: "filename" }, slot: "filename" }, [
            _vm._v("scene.vue")
          ]),
          _c("pre", { attrs: { slot: "pug" }, slot: "pug" }, [
            _c("code", { attrs: { lang: "pug" } }, [
              _c("span", { staticClass: "token tag" }, [_vm._v("Scene")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Box"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _vm._v(":"),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("position")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token number" }, [_vm._v("0")])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "token attr-name" }, [_vm._v("0")]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(' 5]"'),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n")
            ])
          ]),
          _c("pre", { attrs: { slot: "html" }, slot: "html" }, [
            _c("code", { attrs: { lang: "markup" } }, [
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Scene")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Box")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":position")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("[0, 0, 5]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Box")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Scene")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ])
            ])
          ])
        ],
        1
      ),
      _c("h4", [_vm._v("Description")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "This is the entry point for the entire 3D scene. All components of this library require this to be an ancestor."
        )
      ]),
      _vm._v(" "),
      _c("h4", [_vm._v("Details")]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("h4", [_vm._v("Usage")]),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "By default the canvas will use all available space from the parent element. Use the following CSS to fill the entire page with the canvas object:"
        )
      ]),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _c("h4", [_vm._v("Events")]),
      _vm._v(" "),
      _vm._m(4),
      _vm._v(" "),
      _c("h4", [_vm._v("Props")]),
      _vm._v(" "),
      _vm._m(5)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v(
          "Automatically creates the HTML Canvas object to be used by BabylonJS"
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "If there are no cameras defined in the scene then the BabylonJS default environment will be created"
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Although physics options are defined on the scene, it will not be initialized until a "
        ),
        _c("code", [_vm._v("Physics")]),
        _vm._v(" component is used")
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "The model from this component is the raw BabylonJS scene object. Use this to obtain access to the engine and physics engine (when initialized)."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("See "),
      _c(
        "a",
        {
          attrs: { href: "https://doc.babylonjs.com/api/classes/babylon.scene" }
        },
        [_vm._v("the BabylonJS api documentation on the Scene class")]
      ),
      _vm._v(" for more details.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("pre", { staticClass: "language-html" }, [
      _c("code", { staticClass: "language-html" }, [
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Scene")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v("v-model")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("myModel"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n  "),
        _c("span", { staticClass: "token comment" }, [
          _vm._v(
            "<!-- here is where you put other components in this library -->"
          )
        ]),
        _vm._v("\n"),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Scene")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("pre", { staticClass: "language-css" }, [
      _c("code", { staticClass: "language-css" }, [
        _c("span", { staticClass: "token selector" }, [_vm._v("html, body")]),
        _vm._v(" "),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
        _vm._v("\n  "),
        _c("span", { staticClass: "token property" }, [_vm._v("margin")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(":")]),
        _vm._v(" 0"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
        _vm._v("\n  "),
        _c("span", { staticClass: "token property" }, [_vm._v("padding")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(":")]),
        _vm._v(" 0"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
        _vm._v("\n  "),
        _c("span", { staticClass: "token property" }, [_vm._v("height")]),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(":")]),
        _vm._v(" 100%"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v(";")]),
        _vm._v("\n"),
        _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
        _vm._v("\n")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("code", [_vm._v("engine")]),
        _vm._v(" - When the scene object is available")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("scene")]),
        _vm._v(" - When the scene object is available")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("complete")]),
        _vm._v(" - When all child entities are available")
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v("All observables from "),
        _c("code", [_vm._v("Scene")]),
        _vm._v(", see the Observable documentation for details")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("code", [_vm._v("ambient")]),
        _vm._v(" (Color) - Ambient lighting color for the scene")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("fog")]),
        _vm._v(
          " (String) - The type of fog to use, see below for valid values:\n"
        ),
        _c("ul", [
          _c("li", [_c("code", [_vm._v('"none"')])]),
          _vm._v(" "),
          _c("li", [_c("code", [_vm._v('"exp"')])]),
          _vm._v(" "),
          _c("li", [_c("code", [_vm._v('"exp2"')])]),
          _vm._v(" "),
          _c("li", [_c("code", [_vm._v('"linear"')])])
        ])
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("fogStart")]),
        _vm._v(" (Number) - Starting distance for fog")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("fogEnd")]),
        _vm._v(" (Number) - Ending distance for fog")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("fogDensity")]),
        _vm._v(" (Number) - Density of fog for in "),
        _c("code", [_vm._v('"exp"')]),
        _vm._v(" or "),
        _c("code", [_vm._v('"exp2"')]),
        _vm._v(" modes")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("fogColor")]),
        _vm._v(" (Color) - Color to use for fog")
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("fullscreen")]),
        _vm._v(" (Boolean) - Enable fullscreen - default "),
        _c("code", [_vm._v("false")])
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("debug")]),
        _vm._v(" (Boolean) - Enable debug mode - default "),
        _c("code", [_vm._v("false")])
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("environment")]),
        _vm._v(
          " (IEnvironmentHelperOptions) - Options for the default environment: "
        ),
        _c(
          "a",
          {
            attrs: {
              href:
                "https://doc.babylonjs.com/api/interfaces/babylon.ienvironmenthelperoptions"
            }
          },
          [_vm._v("See the interface documentation")]
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("main")]),
        _vm._v(
          " (Color) - Primary color of all the available elements when using the default environment"
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("physics")]),
        _vm._v(
          " (String) - Optionally change the physics engine to use, see below for valid values:\n"
        ),
        _c("ul", [
          _c("li", [_c("code", [_vm._v('"cannon"')])]),
          _vm._v(" "),
          _c("li", [_c("code", [_vm._v('"oimo"')])])
        ])
      ]),
      _vm._v(" "),
      _c("li", [
        _c("code", [_vm._v("gravity")]),
        _vm._v(
          " (Vector3) - Set the direction of gravity when using the physics engine"
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }