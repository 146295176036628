export default {
    methods: {
      onCamera(entity){
        console.log('onCamera', entity)
      }
      ,
      complete(state){
        console.log('complete',state)
      }


    },
  };
  