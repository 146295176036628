var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "height-100 width-100", attrs: { id: "viewport" } },
    [
      _c("div", { staticClass: "frow visible-sm visible-xs" }, [
        _c(
          "div",
          {
            staticClass: "hamburger",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.sidebar = !_vm.sidebar
              }
            }
          },
          [_c("div"), _c("div"), _c("div")]
        ),
        _c("h1", [_vm._v("Vue-BabylonJS")])
      ]),
      _c("div", { staticClass: "frow width-100" }, [
        _c(
          "div",
          {
            staticClass: "col-md-1-5 height-100",
            class: { "sidebar-hidden": !_vm.sidebar }
          },
          [
            _c("div", { staticClass: "sidebar" }, [
              _c(
                "nav",
                {
                  staticClass: "height-100",
                  on: {
                    click: function($event) {
                      _vm.sidebar = false
                    }
                  }
                },
                [
                  _c("div", { staticClass: "hidden-sm hidden-xs" }, [
                    _c(
                      "h1",
                      [
                        _c("router-link", { attrs: { to: "/home" } }, [
                          _vm._v("Vue-BabylonJS")
                        ])
                      ],
                      1
                    )
                  ]),
                  _c(
                    "ul",
                    [
                      _vm._m(0),
                      _c("router-link", { attrs: { tag: "li", to: "/home" } }, [
                        _c("a", [_vm._v("Home")])
                      ]),
                      _c(
                        "router-link",
                        { attrs: { tag: "li", to: "/installation" } },
                        [_c("a", [_vm._v("Installation")])]
                      ),
                      _c(
                        "router-link",
                        { attrs: { tag: "li", to: "/about" } },
                        [_c("a", [_vm._v("About")])]
                      ),
                      _vm._m(1),
                      _c(
                        "router-link",
                        { attrs: { tag: "li", to: "/animation" } },
                        [_c("a", [_vm._v("Animation")])]
                      ),
                      _c(
                        "router-link",
                        { attrs: { tag: "li", to: "/asset" } },
                        [_c("a", [_vm._v("Asset")])]
                      ),
                      _c(
                        "router-link",
                        { attrs: { tag: "li", to: "/camera" } },
                        [_c("a", [_vm._v("Camera")])]
                      ),
                      _c(
                        "router-link",
                        { attrs: { tag: "li", to: "/entity" } },
                        [_c("a", [_vm._v("Entity")])]
                      ),
                      _c(
                        "router-link",
                        { attrs: { tag: "li", to: "/light" } },
                        [_c("a", [_vm._v("Light")])]
                      ),
                      _c(
                        "router-link",
                        { attrs: { tag: "li", to: "/material" } },
                        [_c("a", [_vm._v("Material")])]
                      ),
                      _c("router-link", { attrs: { tag: "li", to: "/mesh" } }, [
                        _c("a", [_vm._v("Mesh")])
                      ]),
                      _c(
                        "router-link",
                        { attrs: { tag: "li", to: "/observable" } },
                        [_c("a", [_vm._v("Observable")])]
                      ),
                      _c(
                        "router-link",
                        { attrs: { tag: "li", to: "/physics" } },
                        [_c("a", [_vm._v("Physics")])]
                      ),
                      _c(
                        "router-link",
                        { attrs: { tag: "li", to: "/property" } },
                        [_c("a", [_vm._v("Property")])]
                      ),
                      _c(
                        "router-link",
                        { attrs: { tag: "li", to: "/scene" } },
                        [_c("a", [_vm._v("Scene")])]
                      ),
                      _c(
                        "router-link",
                        { attrs: { tag: "li", to: "/shader" } },
                        [_c("a", [_vm._v("Shader")])]
                      ),
                      _c(
                        "router-link",
                        { attrs: { tag: "li", to: "/texture" } },
                        [_c("a", [_vm._v("Texture")])]
                      ),
                      _c(
                        "router-link",
                        { attrs: { tag: "li", to: "/types" } },
                        [_c("a", [_vm._v("Types")])]
                      ),
                      _c(
                        "div",
                        { staticClass: "frow" },
                        [
                          _c(
                            "external",
                            {
                              staticClass: "github-logo",
                              attrs: {
                                href: "https://github.com/Beg-in/vue-babylonjs"
                              }
                            },
                            [_c("Logo")],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("p", [
                    _vm._v(
                      "© " + _vm._s(new Date().getFullYear()) + " Brian Jesse"
                    )
                  ])
                ]
              )
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass: "col-md-4-5 height-100",
            class: { "sidebar-hidden": _vm.sidebar }
          },
          [_c("main", [_c("router-view")], 1)]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("div", { staticClass: "folder-name" }, [_vm._v("Introduction")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("div", { staticClass: "folder-name" }, [_vm._v("API")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }