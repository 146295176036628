var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("article", [
      _c("h2", [_vm._v("Camera")]),
      _c("h4", [_vm._v("Description")]),
      _vm._v(" "),
      _c("p", [_vm._v("This component will define a camera in a scene")]),
      _vm._v(" "),
      _c("h4", [_vm._v("Details")]),
      _vm._v(" "),
      _c("p", [
        _vm._v("The "),
        _c(
          "a",
          { attrs: { href: "https://doc.babylonjs.com/babylon101/cameras" } },
          [_vm._v("BabylonJS guide to cameras")]
        ),
        _vm._v(" can be helpful here.")
      ]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _vm._v(
            "Can be defined anywhere as a descendant of the Scene component"
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v("Can be transformed (moved/rotated) by using a parent Entity")
        ])
      ]),
      _vm._v(" "),
      _c("h4", [_vm._v("Usage")]),
      _vm._v(" "),
      _c("pre", { staticClass: "language-html" }, [
        _c("code", { staticClass: "language-html" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
              _vm._v("Scene")
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _vm._v("\n  "),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
              _vm._v("Camera")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "token attr-name" }, [_vm._v("v-model")]),
            _c("span", { staticClass: "token attr-value" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
              _vm._v("myCamera"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
              _vm._v("Camera")
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _vm._v("\n"),
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token tag" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
              _vm._v("Scene")
            ]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
          ]),
          _vm._v("\n")
        ])
      ]),
      _vm._v(" "),
      _c("h4", [_vm._v("Props")]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _c("code", [_vm._v("type")]),
          _vm._v(
            " (String) - The type of camera to use, see below for valid values:\n"
          ),
          _c("ul", [
            _c("li", [_c("code", [_vm._v('"universal"')])]),
            _vm._v(" "),
            _c("li", [_c("code", [_vm._v('"free"')])]),
            _vm._v(" "),
            _c("li", [_c("code", [_vm._v('"follow"')])]),
            _vm._v(" "),
            _c("li", [_c("code", [_vm._v('"arcRotate"')])]),
            _vm._v(" "),
            _c("li", [_c("code", [_vm._v('"arcFollow"')])]),
            _vm._v(" "),
            _c("li", [_c("code", [_vm._v('"deviceOrientation"')])]),
            _vm._v(" "),
            _c("li", [_c("code", [_vm._v('"touch"')])]),
            _vm._v(" "),
            _c("li", [_c("code", [_vm._v('"gamepad"')])])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("code", [_vm._v("position")]),
          _vm._v(" (Vector3) - Starting position for the camera")
        ]),
        _vm._v(" "),
        _c("li", [
          _c("code", [_vm._v("target")]),
          _vm._v(" (Vector3) - Targets the camera to a particular position")
        ]),
        _vm._v(" "),
        _c("li", [
          _c("code", [_vm._v("alpha")]),
          _vm._v(" (Number) - alpha (radians) the longitudinal rotation for "),
          _c("code", [_vm._v('"arcRotate"')]),
          _vm._v(" and "),
          _c("code", [_vm._v('"arcFollow"')]),
          _vm._v(" type cameras")
        ]),
        _vm._v(" "),
        _c("li", [
          _c("code", [_vm._v("beta")]),
          _vm._v(" (Number) - beta (radians) the latitudinal rotation for "),
          _c("code", [_vm._v('"arcRotate"')]),
          _vm._v(" and "),
          _c("code", [_vm._v('"arcFollow"')]),
          _vm._v(" type cameras")
        ]),
        _vm._v(" "),
        _c("li", [
          _c("code", [_vm._v("radius")]),
          _vm._v(" (Number) - The distance from the target for "),
          _c("code", [_vm._v('"arcRotate"')]),
          _vm._v(" and "),
          _c("code", [_vm._v('"arcFollow"')]),
          _vm._v(" type cameras")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }