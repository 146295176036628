var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    [
      _c("h2", [_vm._v("Asset")]),
      _c("h4", [_vm._v("Example")]),
      _c(
        "Controls",
        { attrs: { inline: true, route: "/asset/fullscreen" } },
        [
          _c("Example"),
          _c("h5", { attrs: { slot: "filename" }, slot: "filename" }, [
            _vm._v("asset.vue")
          ]),
          _c("pre", { attrs: { slot: "pug" }, slot: "pug" }, [
            _c("code", { attrs: { lang: "pug" } }, [
              _c("span", { staticClass: "token tag" }, [_vm._v("Scene")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Camera"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("type")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"arcRotate"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("radius"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"7.5"')
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("beta"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _c("span", { staticClass: "token string" }, [
                      _vm._v('"Math.PI / 3"')
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("HemisphericLight")
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Asset"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("src")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v(
                        '"https://www.babylonjs-playground.com/scenes/skull.babylon"'
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("scaling"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token number" }, [
                      _vm._v("0.02")
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" 0."),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("02")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(' 0.02]" :'),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("position")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token number" }, [_vm._v("4")])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" 0."),
                  _c("span", { staticClass: "token attr-name" }, [_vm._v("5")]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(' 0]"'),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Asset"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("src")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v(
                        '"https://www.babylonjs-playground.com/scenes/Buggy/glTF/buggy.gltf"'
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("scaling"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token number" }, [
                      _vm._v("0.02")
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" 0."),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("02")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(' 0.02]" :'),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("position")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token number" }, [_vm._v("1")])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "token attr-name" }, [_vm._v("0")]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(' -1]" :'),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("rotation")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token number" }, [_vm._v("0")])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" Math.PI / "),
                  _c("span", { staticClass: "token attr-name" }, [_vm._v("2")]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(' 0]"'),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Asset"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("src")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v(
                        '"https://www.babylonjs-playground.com/scenes/StanfordBunny.obj"'
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("scaling"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token number" }, [_vm._v("7.5")])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" 7."),
                  _c("span", { staticClass: "token attr-name" }, [_vm._v("5")]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(' 7.5]" :'),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("position")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("-")
                    ]),
                    _c("span", { staticClass: "token number" }, [_vm._v("4")])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" -0."),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("25")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(' -0.5]" :'),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("rotation")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token number" }, [_vm._v("0")])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" Math."),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("PI")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(' 0]"'),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _vm._v("Asset"),
                _c("span", { staticClass: "token attributes" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("(")
                  ]),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("src")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _c("span", { staticClass: "token string" }, [
                      _vm._v(
                        '"https://rawgit.com/saswata26/misc/master/base.stl"'
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v(":")
                    ]),
                    _vm._v("scaling"),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("=")
                    ]),
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token number" }, [
                      _vm._v("0.02")
                    ])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" 0."),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("02")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(' 0.02]" :'),
                  _c("span", { staticClass: "token attr-name" }, [
                    _vm._v("position")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token attr-value" }, [
                    _vm._v('"'),
                    _c("span", { staticClass: "token punctuation" }, [
                      _vm._v("[")
                    ]),
                    _c("span", { staticClass: "token operator" }, [
                      _vm._v("-")
                    ]),
                    _c("span", { staticClass: "token number" }, [_vm._v("2")])
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "token attr-name" }, [_vm._v("0")]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(",")
                  ]),
                  _vm._v(' -0.5]"'),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v(")")
                  ])
                ])
              ]),
              _vm._v("\n")
            ])
          ]),
          _c("pre", { attrs: { slot: "html" }, slot: "html" }, [
            _c("code", { attrs: { lang: "markup" } }, [
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Scene")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Camera")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v("type")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("arcRotate"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":radius")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("7.5"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":beta")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("Math.PI / 3"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Camera")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("HemisphericLight")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("HemisphericLight")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Asset")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [_vm._v("src")]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v(
                    "https://www.babylonjs-playground.com/scenes/skull.babylon"
                  ),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":scaling")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("[0.02, 0.02, 0.02]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":position")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("[4, 0.5, 0]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Asset")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Asset")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [_vm._v("src")]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v(
                    "https://www.babylonjs-playground.com/scenes/Buggy/glTF/buggy.gltf"
                  ),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":scaling")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("[0.02, 0.02, 0.02]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":position")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("[1, 0, -1]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":rotation")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("[0, Math.PI / 2, 0]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Asset")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Asset")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [_vm._v("src")]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v(
                    "https://www.babylonjs-playground.com/scenes/StanfordBunny.obj"
                  ),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":scaling")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("[7.5, 7.5, 7.5]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":position")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("[-4, -0.25, -0.5]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":rotation")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("[0, Math.PI, 0]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Asset")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("<")
                  ]),
                  _vm._v("Asset")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [_vm._v("src")]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("https://rawgit.com/saswata26/misc/master/base.stl"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":scaling")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("[0.02, 0.02, 0.02]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "token attr-name" }, [
                  _vm._v(":position")
                ]),
                _c("span", { staticClass: "token attr-value" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("=")
                  ]),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ]),
                  _vm._v("[-2, 0, -0.5]"),
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v('"')
                  ])
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Asset")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token tag" }, [
                _c("span", { staticClass: "token tag" }, [
                  _c("span", { staticClass: "token punctuation" }, [
                    _vm._v("</")
                  ]),
                  _vm._v("Scene")
                ]),
                _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
              ])
            ])
          ])
        ],
        1
      ),
      _c("h4", [_vm._v("Description")]),
      _vm._v(" "),
      _c("p", [_vm._v("Load an asset file from a URL location")]),
      _vm._v(" "),
      _c("h4", [_vm._v("Details")]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("h4", [_vm._v("Usage")]),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c("h4", [_vm._v("Props")]),
      _vm._v(" "),
      _vm._m(3)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("The BabylonJS "),
      _c(
        "a",
        {
          attrs: {
            href: "https://doc.babylonjs.com/how_to/load_from_any_file_type"
          }
        },
        [_vm._v("guide to loading files")]
      ),
      _vm._v(" can be helpful here.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v(
          "Creates a root mesh for all of the assets contained inside the referred file"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("pre", { staticClass: "language-html" }, [
      _c("code", { staticClass: "language-html" }, [
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Scene")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n  "),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("<")]),
            _vm._v("Asset")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v("src")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("https://example.com/example.gltf"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "token attr-name" }, [_vm._v("v-model")]),
          _c("span", { staticClass: "token attr-value" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("=")]),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')]),
            _vm._v("myAssetRoot"),
            _c("span", { staticClass: "token punctuation" }, [_vm._v('"')])
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Asset")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n"),
        _c("span", { staticClass: "token tag" }, [
          _c("span", { staticClass: "token tag" }, [
            _c("span", { staticClass: "token punctuation" }, [_vm._v("</")]),
            _vm._v("Scene")
          ]),
          _c("span", { staticClass: "token punctuation" }, [_vm._v(">")])
        ]),
        _vm._v("\n")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("code", [_vm._v("src")]),
        _vm._v(" (String) - url path to an asset file of type  "),
        _c("code", [_vm._v("gltf")]),
        _vm._v(", "),
        _c("code", [_vm._v("obj")]),
        _vm._v(", "),
        _c("code", [_vm._v("stl")]),
        _vm._v(", or "),
        _c("code", [_vm._v("babylon")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }